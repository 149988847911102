import css from 'styled-jsx/css';
import { icons, colors } from '../../../config/styles';
import { svgToDataUri } from '../../../utils/styles/svgMixins';

const WarrantySelectEnhancedStyles = css`
  .warranty-wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 13.5px 0 24px 0;
    border-bottom: 2px solid ${colors.white.shade4};

    .title {
      display: flex;
      gap: 8px;
      align-items: center;
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;

      .warranty-icon {
        display: flex;
        height: 24px;
        min-width: 24px;
        background-image: url("${svgToDataUri(icons.warrantyIcon)}");
        background-size: 100%;
        background-position: center;
      }
    }

    .body {
      display: flex;
      flex-direction: column;
      gap: 16px;

      @mixin phabletToDesktop {
        flex-direction: row;
      }

      .sku-list {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;

        @mixin phabletToDesktop {
          width: 50%;
        }

        .warranty-sku {
          display: flex;
          gap: 8px;
          padding: 8px 12px;
          align-self: stretch;
          justify-content: space-between;
          border: 1px solid ${colors.gray.shade70};
          border-radius: 8px;
          font-size: 12px;
          line-height: 16px;

          .label-wrapper {
            display: flex;
            flex-direction: row;
            gap: 8px;
          }

          .warranty-details {
            display: flex;
            flex-direction: column;
            text-align: left;
            gap: 4px;

            .warranty-text {
              display: flex;
              flex-direction: row;
              gap: 8px;
              flex-wrap: wrap;
            }
          }

          .radio-button {
            display: flex;
            height: 20px;
            min-width: 20px;
            background-size: 100%;
            background-position: center;

            &.empty {
              background-image: url("${svgToDataUri(icons.emptyRadioButton)}");
            }
            &.full {
              background-image: url("${svgToDataUri(icons.filledRadioButton)}");
            }
          }

          .recommended-badge {
            background: ${colors.yellow.shade6};
            padding: 2px 4px;
            font-size: 11px;
            font-weight: 700;
            line-height: 12px;
            text-align: center;
            height: fit-content;
            border-radius: 4px;
            width: min-content;
          }

          .price {
            display: flex;
            justify-content: end;
            width: 30%;
            font-weight: 700;
          }

          &.selected {
            background: ${colors.gray.shade71};
            font-weight: 700;

            .tick-icon {
              display: flex;
              height: 12px;
              min-width: 12px;
              background-image: url("${svgToDataUri(icons.tickIcon)}");
              background-size: 100%;
              background-position: center;
            }

            .selected-label {
              display: flex;
              gap: 4px;
              padding: 2px 4px;
              border-radius: 4px;
              align-items: center;
              align-self: flex-start;
              background-color: ${colors.green.shade25};
              font-size: 10px;
              font-weight: 700;
              color: ${colors.white.shade5};
            }
          }
        }
      }

      .benefits-list {
        display: flex;
        flex-direction: column;
        gap: 16px;

        @mixin phabletToDesktop {
          width: 50%;
        }

        .title {
          display: flex;
          justify-content: space-between;
          font-size: 14px;
          line-height: 16px;
          font-weight: 400;

          @mixin phabletToDesktop {
            font-weight: 700;
          }

          .expand {
            display: flex;
            height: 24px;
            min-width: 24px;
            background-image: url("${svgToDataUri(icons.expand)}");
            background-size: 100%;
            background-position: center;
          }

          .collapse {
            display: flex;
            height: 24px;
            min-width: 24px;
            background-image: url("${svgToDataUri(icons.collapse)}");
            background-size: 100%;
            background-position: center;
          }
        }

        .list {
          display: flex;
          flex-direction: column;
          gap: 8px;
          font-size: 12px;
          font-weight: 400;
          line-height: 16px;

          .benefit {
            display: flex;
            gap: 8px;

            .tick-icon {
              display: flex;
              height: 16px;
              min-width: 16px;
              background-image: url("${svgToDataUri(icons.tickIcon2)}");
              background-size: 100%;
              background-position: center;
            }
          }
        }
      }

      .link-wrapper {
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
        margin-left: 24px;
        width: fit-content;

        .more-benefits {
          border-bottom: 0.5px solid ${colors.black.shade1};
        }
      }

      .csicon-right {
        color: ${colors.gray.shade15};
        width: 20px;
        height: 20px;
        font-size: 10px;
        margin-left: 6px;
      }
    }
  }
`;

export { WarrantySelectEnhancedStyles };
