import css from 'styled-jsx/css';
import { colors, typography } from '../../../config/styles';
import { svgToDataUri } from '../../../utils/styles/svgMixins';

const successIconSvgMkp = `<svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M4.80785 23.1922C9.88497 28.2693 18.1161 28.2693 23.1932 23.1922C28.2689 18.115 28.2689 9.88392 23.1932 4.8068C18.1161 -0.268932 9.88497 -0.268932 4.80785 4.8068C-0.269282 9.88392 -0.269282 18.115 4.80785 23.1922Z" stroke="#30871F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M9 14.2538L12.7343 18L19 10" stroke="#30871F" stroke-width="1.5"/>
</svg>`;

const CartItemStyles = css`
  .cart-item-wrapper {
    padding: 14px 0;
    border-bottom: 1px solid #f0f0f0;

    &:last-child {
      border-bottom: none;
    }
  }

  .cart-item {
    display: flex;
    .cart-item-image {
      border: 1px solid #ededed;
      flex-shrink: 0;
    }
    .cart-image-desktop {
      width: 52px;
      height: 52px;
      overflow: hidden;
    }

    .additional-information {
      display: flex;
      margin-left: 18px
    }

    .price {
      width: 145px;
    }

    .stepper {
      display: flex;
      align-items: center;
      height: 32px;
      width: 110px;
      margin-right: 25px;
    }

    .stepper-mobile {
      display: flex;
      align-items: center;
      height: 40px;
      width: 138px;
      margin-right: 35px;
    }

    .maxQuantity-alert {
      width: fit-content;
      @mixin tabletToDesktop {
        width: 188px;
      }
    }
    .max-units-text {
      height: 32px;
      display: flex;
      font-weight: 400;
      font-size: 12px;
      line-height: 14.4px;
      color: #888;
      @mixin tabletToDesktop {
        margin-left: -10px;
        align-items: center;
      }
    }
    .addToCart-button {
      width: 195px;

      span {
        font-weight: bold;
        letter-spacing: 0.92px;
        line-height: 13px;
        text-align: center;
      }
    }

    div {
      flex-shrink: 0;
    }

    img {
      margin-right: 18px;
      height: 46px;
      width: 46px;
      border: 1px solid #ededed;
      flex-shrink: 0;
    }

    .item-info {
      width: 96px;
      margin-right: 36px;

      .brand-name {
        display: block;
        height: 12px;
        color: ${colors.black.shade7};
        font-size: ${typography.xs};
        font-weight: 700;
        letter-spacing: 0.73px;
        line-height: 12px;
      }
    }
  }



  .cart-item-mobile {
    margin-left: 108px;
    :global(.product-count-mobile) {
      width: 112px;
    }
  }
  .services-divider {
    border-top: 1px solid ${colors.gray.shade55};
    margin: 16px 0 10.5px 0;
  }
  .cart-services {
    display: block;
    justify-content: space-between;
    margin-top: 14px;

    :global(.chiplet-wrapper) {
      margin-bottom: 20px;
      @mixin tabletToDesktop {
        padding: 11px 30px 11px 12px;
      }
    }

    @mixin tabletToDesktop {
      display: flex;
      flex-direction: column;
    }

    .list-wrapper {
      display: flex;
      flex-direction: column;
      margin-bottom: 30px;

      @mixin tabletToDesktop {
        width: 319px;
      }
    }

    &.mkp {
      @mixin mobileToTablet {
        padding-top: 15.5px;
      }
    }
  }

  .cart-item-wrapper.mobile {
    margin: 15px 18px 7px 17px;

    &.mkp {
      margin-top: 24px;
      padding: 0;
    }

    .cart-item {

      .cart-image-mobile {
        width: 90px;
        height: 90px;
        overflow: hidden;
      }

      .additional-information {
        display: flex;
        flex-grow: 1;
        flex-shrink: 1;
        flex-direction: column;

        span {
          width: calc(100vw - 144px);
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .item-info {
          width: auto;
          margin: 0;

          :global(span) {
            margin-bottom: 3px;
          }
        }
        @mixin desktop {
          flex-shrink: 0;
        }
      }

      .price {
        margin-bottom: 10px;
      }
    }
  }

  .product-add-text {
    display: flex;
    align-items: center;
    margin: 20px 0 30px;

    @mixin tabletToDesktop {
      margin: 0;
    }

    .csicon-icon-success-circle {
      color: ${colors.green.shade1};
      font-size: ${typography.xl3a};
      margin-right: 12px;
      &.mkp {
        color: ${colors.green.shade20};
        &::before {
          content: url('${svgToDataUri(successIconSvgMkp)}');
        }
      }
    }
  }

  .mt-8px {
    margin-top: 8px;
  }
`;

export { CartItemStyles };
