/* stylelint-disable no-descending-specificity */
import css from 'styled-jsx/css';
import _ from 'lodash';
import { svgToDataUri } from '../../../../../utils/styles/svgMixins';
import { colors, icons, typography } from '../../../../../config/styles';

const StoreListDetailsStyles = (theme) => {
  const secondaryColor =
    _.get(theme, 'secondary_color', null) || colors.secondary_color;
  return css`
  .details-container {
    display: flex;
    font-family: 'Lato', sans-serif;
    margin: 12px 16px 16px;

      @mixin mobileToTablet {
        background: ${colors.white.shade1};
        height: 100%;
        border-radius: 12px;
        margin: 10px 16px 16px;
      }
      &.selected {
        @mixin mobileToTablet {
          outline: 2px solid ${secondaryColor};
          border-radius: 12px;
        }
      }
  }
  .flex {
    display: flex;
    align-items: flex-start;
  }
  .separator {
    border-bottom: 1px solid ${colors.gray.shade68};
    margin: 20px 0 8px 0;
    &:last-child {
      display: none;
      margin: 20px 0 8px 0;
    }
    @mixin mobileToTablet {
      display: none;
    }
  }
  .lists-container {
    overflow-y: scroll;
    height: 290px;
    @mixin mobileToTablet {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: auto;
        background: linear-gradient(180deg, rgba(51, 51, 51, 0.00) 0%, rgba(51, 51, 51, 0.70) 42.37%);
      }
      &.list-view {
        height: 80%;
        background: none;
      }
    .store-box {
      @mixin mobileToTablet {
        display: flex;
      }
      &.list-view {
        flex-direction: column;
      }
    }
  }
  span.hide-in-mobile {
    @mixin mobileToTablet {
      display: none;
    }

  }
  .selection-radio {
    width: 21px;
    height: 21px;
    margin: 7px 5px 0 0;
    @mixin mobileToTablet {
      display: none;
    }
  }
  .store-content {
    width: 100%;
    @mixin mobileToTablet {
      width: 300px;
      padding: 16px;
      box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
      border-radius: 12px;
    }
    &.list-view {
      width: 100%;
    }
  }
  .name-address {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    width: 100%;
    p:first-child {
      font-size: ${typography.base};
      font-weight: bold;
      @mixin mobileToTablet {
        font-size: ${typography.lg1};
      }
    }
    p:last-child {
      font-size: ${typography.base};
      @mixin mobileToTablet {
        font-size: ${typography.sm};
      }
    }
  }
  input[type='radio'] {
    accent-color: ${secondaryColor}; /* Supported in modern browsers */
  }
  .icon {
    display: block;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url('/a/fa/product/static/styles/svg/fcomIcon.svg');
    width: 20px;
    height: 16px;
    margin-top: 2px;
    }
    .sodimac {
      background-image: url('/a/fa/product/static/styles/svg/sodimac.svg');
      width: 20px;
      height: 16px;
    }
    .tottus {
      background-image: url('/a/fa/product/static/styles/svg/tottus.svg');
      width: 22px;
      height: 20px;
    }
    .falabella {
      background-image: url('/a/fa/product/static/styles/svg/falabella.svg');
      width: 20px;
      height: 20px;
    }
    .plaza {
      background-image: url('/a/fa/product/static/styles/svg/mPlazaIcon.svg');
      width: 20px;
      height: 20px;
    }
    .chilexpress {
      background-image: url('/a/fa/product/static/styles/svg/chileExpressIcon.svg');
      width: 20px;
      height: 20px;
    }
    .bluexpress {
      background-image: url('/a/fa/product/static/styles/svg/blueexpressIcon.svg');
      width: 20px;
      height: 20px;
    }
    .metro {
      background-image: url('/a/fa/product/static/styles/svg/metroIcon.svg');
      width: 20px;
      height: 20px;
    }
    .etam {
      background-image: url('/a/fa/product/static/styles/svg/etamIcon.svg');
      width: 20px;
      height: 20px;
    }
    .sybilla {
      background-image: url('/a/fa/product/static/styles/svg/sybillaIcon.svg');
      width: 20px;
      height: 20px;
    }
    .americanino {
      background-image: url('/a/fa/product/static/styles/svg/americaninoIcon.svg');
      width: 20px;
      height: 20px;
    }
    .mango {
      background-image: url('/a/fa/product/static/styles/svg/mangoIcon.svg');
      width: 20px;
      height: 20px;
    }
    .aldo {
      background-image: url('/a/fa/product/static/styles/svg/aldoIcon.svg');
      width: 20px;
      height: 20px;
    }
    .clarks {
      background-image: url('/a/fa/product/static/styles/svg/clarksIcon.svg');
      width: 20px;
      height: 20px;
    }
    .carters {
      background-image: url('/a/fa/product/static/styles/svg/cartersIcon.svg');
      width: 20px;
      height: 20px;
    }
    .clock-icon {
      background-image: url("${svgToDataUri(icons.clock)}");
      width: 17px;
      height: 17px;
      margin-right: 4px;
      @mixin mobileToTablet {
        width: 24px;
        height: 20px;
        margin-top: 0;
      }
    }
    .green-bold {
      color: ${colors.green.shade25};
      font-weight: bold;
      font-size: ${typography.base};
      margin-right: 3px;
    }
    .working-hours > p {
      font-size: ${typography.base};
      color: ${colors.gray.shade52};
    }
    .working-hours {
      margin-top: 10px;
      @mixin mobileToTablet {
        margin-top: 9px;
      }
    }
    .delivery-dates {
      justify-content: space-between;
      padding-bottom: 8px;
      margin-top: 8px;
      font-size: ${typography.base}
    }
`;
};

export default StoreListDetailsStyles;
