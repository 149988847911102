import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Prices from '../../Prices/Prices';
import Button from '../../ui/Button/Button';
import _ from '../../../utils/LodashImports';
import { getTenant } from '../../../utils/tenantHelper';
import Image from '../../ui/Image/Image';
import { ProductsCarouselPodStyles } from './ProductsCarouselPod.style';
import { withLabels } from '../../../utils/LabelsContext';
import RatingsVisual from '../../ui/RatingsVisual/RatingsVisual';
import Reviews from '../../ui/Reviews/Reviews';
import { isWindow } from '../../../utils/deviceChecker';
import useIntersection from '../../../utils/useIntersection';
import useLazyLoadImage from '../../../utils/useLazyLoadImage';
import noop from '../../../utils/noop';
import Badges from '../../Badges/Badges';
import { withConfig } from '../../../utils/ConfigurationContext';
import { getHref } from './utils';
import { getMeasurements } from '../../../utils/variant';

const ProductsCarouselPod = ({
  product,
  appCtx,
  labels,
  details,
  aggregateViewedProducts,
  isSponsored,
  sponsoredClick,
  config
}) => {
  const { deviceType, regionCode } = appCtx;
  const isDesktop = deviceType === 'desktop';
  const isMobile = deviceType === 'mobile';
  const [isButtonVisible, setIsButtonVisible] = useState(!isDesktop);
  const [loadImage, setLoadImage] = useState(false);
  const productBrand = _.get(product, 'brand', '');
  const meatStickers = _.get(product, 'meatStickers', []);
  const isMoreThanOneRating = product.totalReviews && product.totalReviews > 0;
  const enableSponsoredInRecommendation = _.get(
    appCtx,
    'siteConfig.toggles.enableSponsoredInRecommendation',
    false
  );
  const sponsoredText = _.get(labels, 'SPONSORED_TEXT', 'Patrocinado');
  const isRecommendedSponsored =
    enableSponsoredInRecommendation && product?.isSponsored;
  const href = getHref(product, details, isSponsored, config, appCtx);
  const podRef = useRef();
  const inViewport = useIntersection(podRef, '0px', '0.3', false);
  const sendImpression =
    (isRecommendedSponsored || isSponsored) && inViewport && product?.adInfo;

  useEffect(() => {
    if (sendImpression) {
      sponsoredClick(
        'impression',
        '',
        product?.adInfo,
        details?.productPosition
      );
    }
  }, [inViewport]);

  const productRef = React.useRef(null);
  const isCarouselInViewport = useIntersection(productRef, '0px', '1', false);
  const measurement = getMeasurements({
    product,
    productMeasurement: product.measurement
  });

  if (isWindow() && aggregateViewedProducts) {
    if (isCarouselInViewport) {
      aggregateViewedProducts(product.id);
    }
  }
  useLazyLoadImage({ productRef, setLoadImage });

  const areSponsoredClickEventsEnabledWithQueryParams = _.get(
    appCtx,
    'siteConfig.toggles.areSponsoredClickEventsEnabledWithQueryParams',
    false
  );
  const shouldShowPatrocinadoPod =
    isRecommendedSponsored &&
    ((isDesktop && !isButtonVisible) || (isMobile && isButtonVisible));

  const redirectToProduct = () => {
    const eventData = {
      carouselName: details.carouselName,
      currentProductID: details.parentVariantID,
      targetProductID: product.offeringId,
      numberProducts: details.numberProducts,
      productPosition: details.productPosition,
      carouselPosition: details.carouselPosition,
      country: getTenant(regionCode),
      store: appCtx.store
    };

    if (isSponsored || isRecommendedSponsored) {
      if (!areSponsoredClickEventsEnabledWithQueryParams) {
        sponsoredClick(
          'click',
          product.id,
          product.adInfo,
          details.productPosition
        );
      }

      sponsoredClick(
        'visit',
        product.id,
        product.adInfo,
        details.productPosition
      );
    }
    const eventToDigitalData = new CustomEvent('DDPDPCarouselProductClick', {
      bubbles: true,
      detail: eventData
    });
    window.dispatchEvent(eventToDigitalData);
  };
  const imageSrc = product.mediaUrls && product.mediaUrls[0];
  return (
    <div
      onMouseEnter={() => isDesktop && setIsButtonVisible(true)}
      onMouseLeave={() => isDesktop && setIsButtonVisible(false)}
      className={`products-carousel-pod-wrapper ${isSponsored &&
        'sponsored-products-carousel-pod-wrapper'}`}
      ref={productRef}
    >
      {meatStickers && (
        <Badges badgeItems={meatStickers} layout="PDP POD" isSticker />
      )}
      <a
        onClick={redirectToProduct}
        className="pod-anchor"
        href={href}
        aria-hidden="true"
        ref={podRef}
      >
        <div className="products-carousel-pod">
          <div className="products-carousel-pod-layout">
            <div className="image-container">
              <div className="image-wrapper">
                {loadImage && (
                  <Image
                    id={product.id}
                    width={136}
                    height={136}
                    alt={product.displayName}
                    useSrcSet={false}
                    inlineDimensions={false}
                    imageUrl={imageSrc}
                    lazyLoad
                  />
                )}
              </div>
            </div>
            <div className="pod-details">
              <div className="pod-product-brand">{productBrand}</div>
              <div
                className={`pod-product-description ${isSponsored &&
                  'pod-sponsored-product-description'}`}
              >
                {product.displayName}
              </div>
              <div
                className={`pod-product-price ${isSponsored &&
                  'pod-sponsored-product-price'}`}
              >
                <Prices
                  priceList={product.prices}
                  productId={product.productId}
                  page="POD"
                  layout="4_GRID"
                  variant="DESKTOP_4_GRID"
                  discountBadge={product.discountBadge}
                  isSponsoredProduct={isSponsored}
                  measurement={measurement}
                  regionCode={regionCode}
                />
              </div>
              {isMoreThanOneRating && (
                <div className="pod-rating">
                  <RatingsVisual
                    value={Number(product.rating)}
                    productId={product.productId}
                    size="normal"
                  />
                  <Reviews value={Number(product.totalReviews)} />
                </div>
              )}
            </div>
          </div>
        </div>
        {(!isMobile || (isSponsored && isMobile)) && (
          <div
            className={`pod-button ${isSponsored &&
              isMobile &&
              'sponsored-pod-button'}`}
            style={{
              visibility:
                isButtonVisible || (isSponsored && isMobile)
                  ? 'visible'
                  : 'hidden'
            }}
          >
            {isSponsored ? (
              <Button
                type="mkp-quatinery generic-outlined"
                strech="full"
                size="small"
              >
                {labels.ADD_TO_CART}
              </Button>
            ) : (
              <Button type="mkp-secondary generic-outlined">
                {labels.ADD_TO_CART}
              </Button>
            )}
          </div>
        )}
        <div
          className={`patrocinado-pod ${
            shouldShowPatrocinadoPod ? '' : 'hidden'
          }`}
        >
          {isRecommendedSponsored && (
            <div className="sponsored-product-text">{sponsoredText}</div>
          )}
        </div>
      </a>
      <style jsx>{ProductsCarouselPodStyles}</style>
    </div>
  );
};

ProductsCarouselPod.propTypes = {
  product: PropTypes.object.isRequired,
  appCtx: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired,
  labels: PropTypes.object,
  details: PropTypes.object.isRequired,
  aggregateViewedProducts: PropTypes.func.isRequired,
  isSponsored: PropTypes.bool,
  sponsoredClick: PropTypes.func
};

ProductsCarouselPod.defaultProps = {
  labels: { ADD_TO_CART: 'Ver producto' },
  isSponsored: false,
  sponsoredClick: noop
};

export { ProductsCarouselPod };
export default withLabels(withConfig(ProductsCarouselPod));
