import { useState, useCallback } from 'react';
import {
  getFuntionAnalyticsSponsored,
  setSponsoredDisplayDigitalDataImpression,
  setSponsoredDisplayDigitalDataClick
} from '../../containers/ProductContainer/utils';

export const useStripActionHandler = ({ appCtx, config, categoryName }) => {
  const [hasImpressionBeenSent, setHasImpressionBeenSent] = useState(false);

  const { tenant, deviceType, regionCode, store } = appCtx;
  const {
    MABAYA_TOKEN,
    MABAYA_EVENTS_ENABLED,
    FEATURE_TOGGLE_SPONSORED_VAS_SERVICE
  } = config;

  const handleStripAction = useCallback(
    (product, action) => {
      if (product == null) return;
      const sku = product.id;
      const mPos = 1;
      const { adInfo } = product;
      const params = {
        mabayaToken: MABAYA_TOKEN,
        enabled: MABAYA_EVENTS_ENABLED,
        platform: deviceType,
        referrerUrl: window.location.href,
        sku,
        regionCode,
        adInfo,
        mPos,
        categoryName,
        tenant
      };

      const isSponsoredService = FEATURE_TOGGLE_SPONSORED_VAS_SERVICE || false;
      const {
        sponsoredClickService: clickService,
        sponsoredImpressionService: impressionService
      } = getFuntionAnalyticsSponsored(isSponsoredService);

      if (action === 'click') {
        // the redirection is being handled by the "a" tag
        setSponsoredDisplayDigitalDataClick(sku, true);
        clickService(params);
      }

      if (action === 'impression' && !hasImpressionBeenSent) {
        const impressionParams = {
          ...params,
          sku: product?.id,
          adInfo: product.adInfo,
          mPos: '1'
        };
        impressionService(impressionParams);
        setSponsoredDisplayDigitalDataImpression(product?.id, true);
        setHasImpressionBeenSent(true);
      }
    },
    [
      MABAYA_TOKEN,
      MABAYA_EVENTS_ENABLED,
      deviceType,
      regionCode,
      categoryName,
      tenant,
      hasImpressionBeenSent,
      store
    ]
  );

  return { handleStripAction, hasImpressionBeenSent };
};
