export default `<svg width="60" height="80" viewBox="0 0 60 80" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0 8C0 3.58172 3.58172 0 8 0H52C56.4183 0 60 3.58172 60 8V59.4912C60 62.5158 58.2942 65.2818 55.5916 66.6397L32.7145 78.1337C30.386 79.3036 27.6335 79.2661 25.3377 78.0331L4.21477 66.6884C1.61928 65.2945 0 62.5867 0 59.6406V8Z" fill="#1A171B"/>
<rect x="5" y="5" width="50" height="56" rx="6" fill="white"/>
<g clip-path="url(#clip0_1082_26517)">
<path fill-rule="evenodd" clip-rule="evenodd" d="M45.7595 28.3419C47.8092 28.3419 49.4788 29.7317 49.4788 31.5235C49.4788 33.1353 48.0451 34.4151 46.2715 34.6251V33.6192C47.4652 33.4153 48.3632 32.5434 48.3632 31.4935C48.3632 30.2957 47.1912 29.3218 45.7434 29.3218C44.2977 29.3218 43.1238 30.2937 43.1238 31.4935C43.1238 32.5514 44.0357 33.4293 45.2437 33.6233V34.6271C43.3998 34.4171 42.0299 33.0873 42.0299 31.4275C42.0299 29.6678 43.7737 28.3419 45.7595 28.3419ZM37.7565 29.3618C38.6104 29.3618 39.3723 29.6818 39.8662 30.1817L40.6761 29.4818C39.9742 28.8039 38.9303 28.3819 37.7705 28.3819C35.8187 28.3819 34.0949 29.5918 33.9449 31.2356H35.0868C35.2668 30.1777 36.3926 29.3618 37.7565 29.3618ZM10.5159 28.5139H11.5617L14.5074 33.1233L13.9074 34.0592L11.5537 30.3917V34.5231H10.5159V28.5139ZM15.0593 32.2574L14.4634 31.3335L16.2392 28.5139H17.295V34.5231H16.2612V30.3917L15.0593 32.2574ZM23.5123 32.6594L24.4701 34.5231H25.716L22.5884 28.5139H21.4645L21.4625 28.5159L23.0303 31.7015H20.9486L21.4905 30.6636L20.9246 29.5338L18.2889 34.5231H19.4548L20.4406 32.6614L23.5123 32.6594ZM31.5832 28.5139H32.6211V32.4474L31.5832 31.4315V28.5139ZM27.6157 28.5139H26.5619V34.5231H27.5997V30.0317L31.8492 34.5231H32.6211V33.7932L27.6157 28.5139ZM37.6605 33.6832C36.5226 33.6832 35.5527 33.0813 35.1928 32.2414H34.0289C34.4149 33.6913 35.9287 34.6571 37.7265 34.6571C38.1444 34.6571 38.6244 34.6171 39.0223 34.5211C39.9222 34.3032 40.9061 33.7333 40.9061 32.8333V31.2356H37.6505L37.6485 32.2414H39.8662V32.6974C39.8662 33.2013 39.0923 33.4933 38.6204 33.5953C38.3664 33.6513 38.0385 33.6832 37.7765 33.6832H37.6605Z" fill="#1A171B"/>
</g>
<defs>
<clipPath id="clip0_1082_26517">
<rect width="40" height="6.99913" fill="white" transform="translate(10 28)"/>
</clipPath>
</defs>
</svg>`;
