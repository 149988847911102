import css from 'styled-jsx/css';
import { colors, typography } from '../../config/styles';

export const DarInformationCardCss = css`
  .container {
    display: flex;
    padding: ${typography.xxs};
    justify-content: center;
    align-items: center;
    gap: ${typography.xxs3};
    align-self: stretch;
    border-radius: ${typography.xxs3};
    background: ${colors.gray.shade32};
    color: ${colors.black.shade1};
    font-family: Lato;
    font-size: ${typography.xla};
    font-style: normal;
    font-weight: 400;
    line-height: ${typography.xl3};
    margin-top: 16px;
  }
`;
