import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '@digital-retail/falabella-ui-cross-components';
import {
  CARTLINE_QUANTITIES_COOKIE_NAME,
  parseCartQuantities
} from '@digital-retail/store-manager';
import _ from '../../utils/LodashImports';
import { withProduct } from '../../utils/ProductContext';
import { withCart } from '../../utils/CartContext';
import Button from '../ui/Button/Button';
import AddToCartPersistContainerStyles from './AddToCartPersistContainer.style';
import { getCartItem } from '../Cart/CartItem/CartItem.util';
import { getMeasurements } from '../../utils/variant';
import CookieStorage from '../../utils/CookieStorage';
import { capitalizeString } from '../../utils/jsUtils';
import { countDecimalPlaces } from '../../utils/PriceHelpers';

const AddToCartPersistContainer = ({
  isAddToCartInProgress = false,
  labels,
  handleAddToCart,
  getATCButtonText,
  inputVal,
  maxCartQuantity,
  handleRemoveFromCart,
  isRemoveFromCartInProgress,
  product,
  theme,
  cart
}) => {
  const { currentVariant: currentVariantId } = product;
  const [skuCartCount, setSkuCartCount] = useState(0);
  const [cartLineId, setCartLineId] = useState('');
  const styles = AddToCartPersistContainerStyles(theme);
  const cartItem = getCartItem({
    skuId: currentVariantId,
    cartItems: cart.cartItems
  });

  const measurement = getMeasurements({ product });
  const unitOfMeasurement = measurement?.['unidad-de-medida'] || '';
  const minSaleQty = measurement?.skuminsaleqty ?? 1;
  const maxCartCount = maxCartQuantity / minSaleQty;

  const maxQuantityText = _.get(
    labels,
    'MAX_QUANTITY_TEXT_PDP',
    'Máximo {maxCartQuantity} {unitOfMeasurement}.'
  )
    .replace('{maxCartQuantity}', maxCartQuantity)
    .replace(
      '{unitOfMeasurement}',
      capitalizeString(unitOfMeasurement) || 'unidades'
    );

  useEffect(() => {
    if (cartItem) {
      setCartLineId(cartItem.cartLineId);
      setSkuCartCount(cartItem.totalQuantity);
      return;
    }
    const cartPersistCookieValue = CookieStorage.getCookie(
      CARTLINE_QUANTITIES_COOKIE_NAME
    );
    const cartPersistValue = cartPersistCookieValue
      ? parseCartQuantities(cartPersistCookieValue)
      : null;
    const cartValue = cartPersistValue?.get(currentVariantId) || {};
    setSkuCartCount(cartValue.quantity || 0);
    setCartLineId(cartValue.cartLineId || '');
  }, [cartItem]);

  const getSkuQuantity = () => {
    const decimalPlaces = countDecimalPlaces(minSaleQty);
    const quantity = Number(minSaleQty * skuCartCount);
    return quantity.toFixed(decimalPlaces);
  };

  const handleCartAction = useCallback(
    (actionType) => {
      if (isAddToCartInProgress || isRemoveFromCartInProgress) {
        return;
      }

      if (actionType === 'add') {
        if (skuCartCount >= maxCartCount) return;
        handleAddToCart(inputVal);
      } else if (actionType === 'remove') {
        handleRemoveFromCart({
          totalQuantity: skuCartCount,
          cartLineId,
          quantity: 1
        });
      }
    },
    [
      skuCartCount,
      maxCartCount,
      isAddToCartInProgress,
      isRemoveFromCartInProgress,
      inputVal,
      cartLineId
    ]
  );

  const renderCartActionContainer = () => (
    <>
      <div
        className={`cart-persist ${
          isAddToCartInProgress || isRemoveFromCartInProgress ? 'disabled' : ''
        }`}
      >
        <button
          className="remove-from-cart-button"
          type="submit"
          onClick={() => handleCartAction('remove')}
        >
          <span
            className={`remove-from-cart-icon ${
              skuCartCount === 1 ? 'trash' : 'minus'
            }`}
          />
        </button>
        <div className="count-from-cart">
          {getSkuQuantity()} {unitOfMeasurement.toLowerCase()}
        </div>
        <button
          type="submit"
          className={`add-to-cart-button ${
            skuCartCount >= maxCartCount ? 'disabled' : ''
          }`}
          onClick={() => handleCartAction('add')}
        >
          <span className="add-to-cart-icon" />
        </button>
      </div>
      <div className="max-units-text">
        {maxCartQuantity && <p>{maxQuantityText}</p>}
      </div>
      <style jsx>{styles}</style>
    </>
  );

  return (
    <div className="persist-container">
      {skuCartCount > 0 ? (
        renderCartActionContainer()
      ) : (
        <Button
          key="add-to-cart-button"
          disabled={isAddToCartInProgress || isRemoveFromCartInProgress}
          size="xtra-large"
          id="add-to-cart-button"
          type="mkp-primary"
          onClick={() => handleCartAction('add')}
        >
          {isAddToCartInProgress ? labels.ADDING_TO_CART : getATCButtonText()}
        </Button>
      )}

      <style jsx>{styles}</style>
    </div>
  );
};

AddToCartPersistContainer.defaultProps = {
  isAddToCartInProgress: false,
  maxCartQuantity: 999,
  product: {},
  cart: {},
  isRemoveFromCartInProgress: false
};

AddToCartPersistContainer.propTypes = {
  isAddToCartInProgress: PropTypes.bool,
  labels: PropTypes.object.isRequired,
  handleAddToCart: PropTypes.func.isRequired,
  getATCButtonText: PropTypes.string.isRequired,
  inputVal: PropTypes.number.isRequired,
  maxCartQuantity: PropTypes.number,
  handleRemoveFromCart: PropTypes.func.isRequired,
  isRemoveFromCartInProgress: PropTypes.bool,
  theme: PropTypes.object.isRequired,
  cart: PropTypes.object,
  product: PropTypes.object
};

export default withTheme(withCart(withProduct(AddToCartPersistContainer)));
export { AddToCartPersistContainer };
