import React from 'react';
import PropTypes from 'prop-types';
import { withApplicationContext } from '../../../utils/ApplicationContext';
import SingleChipletSelect from '../SingleChipletSelect/SingleChipletSelect';
import ServicesSelectEnhanced from './ServicesSelectEnhanced';
import { ServicesSelectStyles } from './SevicesSelect.style';
import _ from '../../../utils/LodashImports';

const ServicesSelect = (props) => {
  const { appCtx } = props;
  const isImprovedServicesUiEnabled = _.get(
    appCtx,
    'siteConfig.toggles.isImprovedServicesUiEnabled',
    true
  );
  return isImprovedServicesUiEnabled ? (
    <ServicesSelectEnhanced {...props} />
  ) : (
    <div className="list-wrapper">
      <SingleChipletSelect {...props} />
      <style jsx>{ServicesSelectStyles}</style>
    </div>
  );
};

ServicesSelect.defaultProps = {
  appCtx: {}
};

ServicesSelect.propTypes = {
  appCtx: PropTypes.object
};

export default withApplicationContext(ServicesSelect);
