import css from 'styled-jsx/css';
import { icons, colors } from '../../../config/styles';
import { svgToDataUri } from '../../../utils/styles/svgMixins';

const ServicesSelectStyles = css`
  .list-wrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;

    @mixin tabletToDesktop {
      width: 319px;
    }
  }
`;

const ServicesSelectEnhancedStyles = css`
  .service-options-wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin: 24px 0 52px 0;

    .title {
      display: flex;
      gap: 8px;
      align-items: center;
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;

      .services-icon {
        display: flex;
        height: 24px;
        min-width: 24px;
        background-image: url("${svgToDataUri(icons.servicesIcon)}");
        background-size: 100%;
        background-position: center;
      }
    }

    .body {
      display: flex;
      flex-direction: column;
      gap: 16px;

      @mixin phabletToDesktop {
        flex-direction: row;
      }

      .sku-list {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;

        @mixin phabletToDesktop {
          width: 50%;
        }

        .service-sku {
          display: flex;
          padding: 8px 12px;
          align-self: stretch;
          justify-content: space-between;
          border: 1px solid ${colors.gray.shade70};
          border-radius: 8px;
          font-size: 12px;
          font-weight: 400;
          line-height: 16px;
          text-align: left;

          &.selected {
            background: ${colors.gray.shade71};
            font-weight: 700;
          }

          .label {
            display: flex;
            justify-self: stretch;
            align-items: center;
            justify-content: space-between;
            gap: 8px;

            .checkbox {
              display: flex;
              height: 20px;
              min-width: 20px;
              background-size: 100%;
              background-position: center;

              &.full {
                background: ${colors.gray.shade64};
                border-radius: 4px;
                background-image: url("${svgToDataUri(icons.filledCheckBox)}");
              }

              &.empty {
                background-image: url("${svgToDataUri(icons.emptyCheckBox)}");
              }
            }
          }

          .price {
            display: flex;
            justify-content: end;
            width: 40%;
            font-weight: 700;
          }
        }
      }

      .benefits-list {
        display: flex;
        flex-direction: column;
        gap: 16px;

        @mixin phabletToDesktop {
          width: 50%;
        }

        .title {
          display: flex;
          justify-content: space-between;
          font-size: 14px;
          line-height: 16px;
          font-weight: 400;

          @mixin phabletToDesktop {
            font-weight: 700;
          }

          .expand {
            display: flex;
            height: 24px;
            min-width: 24px;
            background-image: url("${svgToDataUri(icons.expand)}");
            background-size: 100%;
            background-position: center;
          }

          .collapse {
            display: flex;
            height: 24px;
            min-width: 24px;
            background-image: url("${svgToDataUri(icons.collapse)}");
            background-size: 100%;
            background-position: center;
          }
        }

        .list {
          display: flex;
          flex-direction: column;
          gap: 8px;
          font-size: 12px;
          font-weight: 400;
          line-height: 16px;

          .benefit {
            display: flex;
            gap: 8px;

            .tick-icon {
              display: flex;
              height: 16px;
              min-width: 16px;
              background-image: url("${svgToDataUri(icons.tickIcon2)}");
              background-size: 100%;
              background-position: center;
            }

            .label {
              display: flex;
              flex-direction: column;

              .title {
                font-size: 12px;
                font-weight: 700;
              }

              .description {
                font-size: 12px;
              }
            }
          }
        }
      }

      .link-wrapper {
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
        margin-left: 24px;
        width: fit-content;

        .more-information {
          border-bottom: 0.5px solid ${colors.black.shade1};
        }
      }

      .csicon-right {
        color: ${colors.gray.shade15};
        width: 20px;
        height: 20px;
        font-size: 10px;
        margin-left: 6px;
      }
    }
  }
`;

export { ServicesSelectStyles, ServicesSelectEnhancedStyles };
