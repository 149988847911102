import css from 'styled-jsx/css';
import { colors, zIndex, typography } from '../../../config/styles';

const ModalStyles = css`
  @keyframes frombottom {
    from {
      transform: translateY(100%);
    }
    to {
      transform: translateY(calc(100% - 364px));
    }
  }
  .modal-container {
    pointer-events: all;
    display: flex;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 110;
    cursor: default;
    align-items: flex-start;
    justify-content: flex-start;
    @mixin phabletToDesktop {
      align-items: center;
      justify-content: center;
    }
  }
  .error-modal {
    top: 25%;
    padding-left: 20px;
    padding-right: 20px;
  }

  .modal-container-desktop {
    min-width: 720px;
  }

  .modal {
    background-color: ${colors.white.shade1};
    position: relative;
    overflow: scroll;
    width: auto;

    &.withPadding {
      padding: 10px 15px;
    }

    height: 100%;
    white-space: normal;
    @mixin phabletToDesktop {
      height: auto;
      width: auto;
      border-radius: 4px;
    }
    @mixin phabletOnly {
      height: 100vh;
      width: 100vw;
    }
    .modal-busy-overlay {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      z-index: ${zIndex.loader.overlay};
      font-size: ${typography.base};
      display: flex;
      background: linear-gradient(135deg, #fafafa, #fdfdfd);
      opacity: 0.6;
    }
  }

  .modal-close {
    position: absolute;
    right: 24px;
    top: 27px;
    color: ${colors.gray.shade5};
    font-size: 12px;
    z-index: 1;
    @mixin mobileToTablet {
      font-size: 15px;
    }
    &.new-close-btn {
      @mixin mobileToTablet {
        width: 32px;
        height: 32px;
        background: ${colors.gray.shade69};
        right: 16px;
        border-radius: 50%;
      }
    }
  }

  .modal-header {
    &.withPadding {
      padding: 27px 30px;
    }

    font-size: 22px;
    font-weight: 400;
    border-bottom: 1px solid ${colors.gray.shade14};
    display: flex;
    align-items: center;
    i {
      color: ${colors.green.shade1};
    }
  }

  .modal-body {
    height: 100%;
    width: 100%;

    &.withPadding {
      padding: 20px 25px;
    }

    &.full-screen {
      width: 100%;
      height: 100%;
    }
    &.full-screen.with-header {
      top: 64px;
    }
    &.auto {
      height: auto;
    }
  }

  .modal-container :global(.doc-click-overlay) {
    height: 100%;
    width: 100%;
    align-items: flex-start;
    cursor: auto;
    &.full-screen {
      width: 100%;
      height: 100%;
    }
    &.full-screen.with-header {
      top: 64px;
    }
    @mixin phabletToDesktop {
      height: auto;
      width: auto;
      align-items: inherit;
    }
  }

  .modal-container.bottom :global(.doc-click-overlay) {
    @mixin mobileToTablet {
      align-items: flex-end;
    }
    .modal {
      border-radius: 0;
      @mixin mobileToTablet {
        box-shadow: 0 5px 20px rgb(0 0 0 / 20%);
        border-radius: 10px 10px 0 0;
      }
    }
  }
`;

const ModalStylesGlobal = css.global`
  .no-scroll {
    overflow: hidden;
    pointer-events: none;
  }
`;

const clickElementStyle = css`
  .click-element {
    outline: none;
  }
`;

export { ModalStyles, ModalStylesGlobal, clickElementStyle };
