import React from 'react';
import PropTypes from 'prop-types';
import _ from '../../../utils/LodashImports';
import WarrantySelectEnhanced from './WarrantySelectEnhanced';
import WarrantySelectLegacy from './WarrantySelectLegacy';
import { withApplicationContext } from '../../../utils/ApplicationContext';

const WarrantySelect = (props) => {
  const { appCtx } = props;
  const isImprovedWarrantyUiEnabled = _.get(
    appCtx,
    'siteConfig.toggles.isImprovedWarrantyUiEnabled',
    true
  );

  return isImprovedWarrantyUiEnabled ? (
    <WarrantySelectEnhanced {...props} />
  ) : (
    <WarrantySelectLegacy {...props} />
  );
};

WarrantySelect.propTypes = {
  appCtx: PropTypes.object.isRequired
};

export default withApplicationContext(WarrantySelect);
