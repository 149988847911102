import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { LoginModal } from '@digital-retail/falabella-ui-cross-components';
import { withCart } from '../../utils/CartContext';
import { Modal } from '../ui/Modal/Modal';
import MatchMedia from '../ui/MatchMedia/MatchMedia';
import CartDesktop from './CartDesktop/CartDesktop';
import { withApplicationContext } from '../../utils/ApplicationContext';
import CartMobile from './CartMobile/CartMobile';
import SelectVariant from './SelectVariant/SelectVariant';
import { withProduct } from '../../utils/ProductContext';
import { isEmpty } from '../../utils/jsUtils';
import _ from '../../utils/LodashImports';
import { withUser } from '../../utils/UserContext';
import noop from '../../utils/noop';
import { isWindow } from '../../utils/deviceChecker';

const CartSection = ({ setBusy, cart, hasCrossSelling, setCrossSelling }) => (
  <MatchMedia
    content={{
      desktop: () => (
        <CartDesktop
          cart={cart}
          setBusy={setBusy}
          hasCrossSelling={hasCrossSelling}
          setCrossSelling={setCrossSelling}
        />
      ),
      mobile: () => <CartMobile cart={cart} setBusy={setBusy} />,
      tablet: () => <CartMobile cart={cart} setBusy={setBusy} />
    }}
  />
);

CartSection.propTypes = {
  setBusy: PropTypes.func.isRequired,
  cart: PropTypes.object.isRequired,
  hasCrossSelling: PropTypes.bool.isRequired,
  setCrossSelling: PropTypes.func.isRequired
};

const Cart = ({ cart, appCtx, product, refreshUserData }) => {
  const { deviceType, isRebrandingEnabled } = appCtx;
  const { totalProductsCount, isVariantSelectionVisible } = cart;

  const { linkedProducts, attributes, bundles } = product;
  const isTypeCAvailableInLightbox = _.get(
    appCtx,
    'siteConfig.toggles.isTypeCAvailableInLightbox',
    false
  );

  const [hasCrossSelling, setCrossSelling] = useState(
    (bundles && bundles.length > 0) ||
      (isTypeCAvailableInLightbox && !isEmpty(linkedProducts)) ||
      (attributes && attributes.viewTemplate === 'TYPE_A')
  );
  const atgBaseUrl = _.get(
    appCtx,
    'siteConfig.endpoints.ATG_AUTH_BASEURL',
    'https://www.falabella.com'
  );
  const catalystBaseUrl = _.get(
    appCtx,
    'siteConfig.endpoints.CATALYST_AUTH_BASEURL',
    'https://www.falabella.com'
  );

  const toggleLoginForm = () => {
    cart.setShowLoginForm(!cart.showLoginForm);
  };
  const onClose = () => {
    toggleLoginForm();
  };
  const onUserLogin = () => {
    refreshUserData();
  };

  const currentScreenHeight = isWindow() ? window.innerHeight : 0;
  const warrantyHeightStyle = currentScreenHeight <= 768 ? '100vh' : '100%';
  const height = product.warrantyOptions ? warrantyHeightStyle : 575;
  const cartModalHeight = hasCrossSelling ? height : 300;

  const modalContent = (actions) => {
    const { setBusy } = actions;
    return isVariantSelectionVisible ? (
      <SelectVariant cart={cart} />
    ) : (
      <CartSection
        setBusy={setBusy}
        cart={cart}
        hasCrossSelling={hasCrossSelling}
        setCrossSelling={setCrossSelling}
      />
    );
  };

  useEffect(() => {
    if (typeof totalProductsCount !== 'undefined') {
      appCtx.changeCartCount(totalProductsCount);
    }
  }, [totalProductsCount]);

  const modalHeight = isVariantSelectionVisible ? 490 : cartModalHeight;

  return (
    <Fragment>
      <Modal
        showCloseButton={false}
        toggleState={{ setVisible: cart.setShowCart, visible: cart.showCart }}
        content={modalContent}
        withPadding={false}
        fullScreen={deviceType !== 'desktop'}
        options={{
          disableOutsideClick: true,
          width: deviceType === 'desktop' ? 800 : '100vw',
          height: deviceType === 'desktop' ? modalHeight : '100vh',
          overflow: 'hidden'
        }}
        deviceType={deviceType}
        isRebrandingEnabled={isRebrandingEnabled}
      />
      <LoginModal
        id="testId-atc-cart"
        isRebrandingEnabled={appCtx.isRebrandingEnabled}
        atgBaseUrl={atgBaseUrl}
        catalystBaseUrl={catalystBaseUrl}
        onClose={onClose}
        onUserLogin={onUserLogin}
        tenant={appCtx.regionCode}
        showLoginForm={cart.showLoginForm}
      />
    </Fragment>
  );
};

Cart.defaultProps = {
  refreshUserData: noop
};
Cart.propTypes = {
  cart: PropTypes.object.isRequired,
  appCtx: PropTypes.object.isRequired,
  product: PropTypes.object.isRequired,
  refreshUserData: PropTypes.func
};

export default withApplicationContext(withProduct(withCart(withUser(Cart))));
export { Cart, CartSection };
