import React from 'react';
import PropTypes from 'prop-types';
import { DarInformationCardCss } from './DarInformationCard.styles';

const DarInformationCard = ({ visible, labels }) => {
  if (!visible) return null;
  const { darSectionLabel = 'Producto sin Derecho a retracto' } = labels ?? {};
  return (
    <section className="container">
      {darSectionLabel}
      <style jsx>{DarInformationCardCss}</style>
    </section>
  );
};

DarInformationCard.propTypes = {
  labels: PropTypes.object.isRequired,
  visible: PropTypes.bool.isRequired
};

export default DarInformationCard;
