export default `<svg width="60" height="80" viewBox="0 0 60 80" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0 8C0 3.58172 3.58172 0 8 0H52C56.4183 0 60 3.58172 60 8V59.4912C60 62.5158 58.2942 65.2818 55.5916 66.6397L32.7146 78.1337C30.386 79.3036 27.6335 79.2661 25.3377 78.0331L4.21477 66.6884C1.61928 65.2945 0 62.5867 0 59.6406V8Z" fill="#ACACAC"/>
<rect x="5" y="5" width="50" height="58" rx="6" fill="white"/>
<path d="M15.4779 31.2997V31.3368H13.9142V31.2997C13.9142 30.618 13.3782 30.1945 12.3505 30.1832C11.3228 30.1718 10.8013 30.6294 10.7868 31.2997C10.7868 32.4048 15.7124 32.4275 15.701 35.1211C15.701 36.5392 14.0371 37.79 12.3619 37.7786C10.6866 37.7673 9 36.5392 9 35.1098V35.0726H10.5637V35.1098C10.5637 35.7574 11.201 36.2263 12.3619 36.2149C13.5228 36.2036 14.1487 35.7574 14.1373 35.1211C14.1373 33.985 9.23549 33.985 9.22413 31.2997C9.22413 29.8702 10.7868 28.6308 12.3505 28.6195C13.9142 28.6081 15.4779 29.8816 15.4779 31.2997Z" fill="#ACACAC"/>
<path d="M20.9943 30.8752L21.1843 30.3733H22.8596L22.4464 31.434L20.2351 37.2292C20.1939 37.3875 20.1343 37.5404 20.0575 37.6847L20.0224 37.7766H20.0069C19.4905 38.6731 18.3957 39.4095 17.7894 39.7648L16.6171 40.4341V38.6256L17.0055 38.4067C17.6138 38.0669 18.3905 37.5412 18.658 37.0041L16.5293 31.4381L16.1162 30.3774H17.7915L17.9815 30.8794L19.4843 34.8062L20.9943 30.8752Z" fill="#ACACAC"/>
<path d="M25.134 37.1506V37.7765H23.5703V27.9646H25.134V31.0125C25.6462 30.6149 26.2726 30.3922 26.9208 30.3773C28.6963 30.3773 30.2817 32.1858 30.2713 34.0841C30.261 35.9825 28.7076 37.7868 26.9208 37.7765C26.2734 37.7655 25.6468 37.546 25.134 37.1506ZM25.134 34.08C25.134 35.3979 25.857 36.2242 26.9208 36.2128C27.9846 36.2014 28.719 35.4093 28.7076 34.08C28.6963 32.7507 27.9929 31.9472 26.9208 31.9358C25.8487 31.9245 25.134 32.7518 25.134 34.08Z" fill="#ACACAC"/>
<path d="M31.7117 31.154V30.3722H33.2743V31.154V36.9947V37.7766H31.7117V36.9947V31.154Z" fill="#ACACAC"/>
<path d="M32.4978 29.1235C32.2162 29.1235 31.9461 29.0116 31.747 28.8125C31.5479 28.6134 31.436 28.3433 31.436 28.0618C31.436 27.7802 31.5479 27.5101 31.747 27.311C31.9461 27.1119 32.2162 27 32.4978 27H32.5122C32.7938 27 33.0639 27.1119 33.263 27.311C33.4621 27.5101 33.574 27.7802 33.574 28.0618C33.574 28.3433 33.4621 28.6134 33.263 28.8125C33.0639 29.0116 32.7938 29.1235 32.5122 29.1235H32.4978Z" fill="#ACACAC"/>
<path d="M50.9236 37.7766C50.7842 37.7767 50.6461 37.7494 50.5173 37.6961C50.3885 37.6429 50.2715 37.5648 50.1729 37.4663C50.0743 37.3678 49.996 37.2508 49.9427 37.122C49.8893 36.9933 49.8618 36.8553 49.8618 36.7159C49.8618 36.5765 49.8893 36.4385 49.9427 36.3097C49.996 36.181 50.0743 36.064 50.1729 35.9655C50.2715 35.867 50.3885 35.7888 50.5173 35.7356C50.6461 35.6824 50.7842 35.655 50.9236 35.6552H50.938C51.0774 35.655 51.2155 35.6824 51.3443 35.7356C51.4731 35.7888 51.5901 35.867 51.6887 35.9655C51.7873 36.064 51.8656 36.181 51.9189 36.3097C51.9723 36.4385 51.9998 36.5765 51.9998 36.7159C51.9998 36.8553 51.9723 36.9933 51.9189 37.122C51.8656 37.2508 51.7873 37.3678 51.6887 37.4663C51.5901 37.5648 51.4731 37.6429 51.3443 37.6961C51.2155 37.7494 51.0774 37.7767 50.938 37.7766H50.9236Z" fill="#D60303"/>
<path d="M35.1626 28.7424V27.9606H36.7263V28.7424V36.9948V37.7766H35.1626V36.9948V28.7424Z" fill="#ACACAC"/>
<path d="M38.8364 28.7424V27.9606H40.4001V28.7424V36.9948V37.7766H38.8364V36.9948V28.7424Z" fill="#ACACAC"/>
<path d="M41.5051 34.0801C41.5051 32.1591 43.1019 30.3836 44.8556 30.3733C45.5035 30.3847 46.1303 30.6054 46.6424 31.0023V30.3733H48.2051V37.7766H46.6476V37.1569C46.1355 37.5528 45.508 37.7704 44.8608 37.7766C43.0854 37.7766 41.5103 35.9785 41.5103 34.0801M43.074 34.0801C43.074 35.398 43.797 36.2243 44.8608 36.2129C45.9246 36.2016 46.6362 35.4228 46.6476 34.14V34.0801C46.6476 32.7519 45.9329 31.9473 44.8608 31.936C43.7887 31.9246 43.074 32.7519 43.074 34.0801Z" fill="#ACACAC"/>
</svg>`
