import {
  sponsoredClickService,
  sponsoredImpressionService,
  sponsoredVisitService
} from '../../services/sponsoredService';
import sponsoredEvents from '../../utils/SponsoredHelper';
import { isWindow } from '../../utils/deviceChecker';
import constants from '../../config/constants';

const getFuntionAnalyticsSponsored = (isSponsoredService) => {
  if (isSponsoredService)
    return {
      sponsoredClickService,
      sponsoredImpressionService,
      sponsoredVisitService
    };

  return {
    sponsoredClickService: sponsoredEvents.click,
    sponsoredImpressionService: sponsoredEvents.impression,
    sponsoredVisitService: sponsoredEvents.visit
  };
};
const getUrl = (url, store) => {
  const { TOTTUS, LINIO, SODIMAC, HOMECENTER } = constants.STORE_NAMES_FOR_VAS;

  const storesAvaliable = [TOTTUS, LINIO, SODIMAC, HOMECENTER];

  if (storesAvaliable.includes(store)) return `${url}?exp=${store}`;

  return url;
};

const getBaseDomain = (url) => {
  if (typeof url !== 'string') {
    return null;
  }
  const urlObj = new URL(url);
  return urlObj.origin;
};
const getUrlBase = (config) => {
  const urlBase = isWindow() ? config.API_HOST : config.CLUSTER_API_HOST;

  return getBaseDomain(urlBase);
};

const setSponsoredDisplayDigitalDataImpression = (skus, isStrip) => {
  const sdComponent = isStrip ? 'SD_Strip' : 'SD';

  const eventToDigitalData = new CustomEvent(
    'DDPDPSponsoredDisplayImpression',
    {
      bubbles: true,
      detail: {
        payload: `${sdComponent}|${skus}`
      }
    }
  );
  window.dispatchEvent(eventToDigitalData);
};

const setSponsoredDisplayDigitalDataClick = (sku, isStrip) => {
  const sdComponent = isStrip ? 'SD_Strip' : 'SD';
  const eventToDigitalData = new CustomEvent('DDPDPSponsoredDisplayClick', {
    bubbles: true,
    detail: {
      payload: `${sdComponent}|${sku}`
    }
  });
  window.dispatchEvent(eventToDigitalData);
};

const setSponsoredDisplayDigitalDataRenderPDP = (product, isStrip) => {
  if (!product) return;
  const sdComponent = isStrip ? 'SD_Strip' : 'SD';
  const eventToDigitalData = new CustomEvent('DDPDPSponsoredDisplayRender', {
    bubbles: true,
    detail: {
      payload: `${sdComponent}|${product?.id}`
    }
  });
  window.dispatchEvent(eventToDigitalData);
};

export {
  getFuntionAnalyticsSponsored,
  getUrlBase,
  getBaseDomain,
  getUrl,
  setSponsoredDisplayDigitalDataImpression,
  setSponsoredDisplayDigitalDataClick,
  setSponsoredDisplayDigitalDataRenderPDP
};
