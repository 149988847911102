import { icons } from './styles';

const constants = {
  lang: 'lang',
  searchTerm: 'Ntt',
  DEFAULT_LAYOUT: 'LIST',
  SESSION_STORAGE_SCROLL: 'scroll',
  SESSION_STORAGE_NEW_PAGE: 'newPage',
  ZONE: {
    COOKIE_NAME: 'zoneData',
    DEFAULT_ZONE_ID: '13',
    DEFAULT_ZONE_NAME: 'R. Metropolitana'
  },
  STORE_NAMES_FOR_VAS: {
    SODIMAC: 'sodimac',
    TOTTUS: 'tottus',
    LINIO: 'linio',
    TIENDA: 'tienda',
    HOMECENTER: 'homecenter',
    SO_COM: 'so_com'
  },
  X_CHANNEL_IDS_HEADERS_VALUES: {
    WEB: 'WEB',
    MOBILE: 'MOBILE',
    TABLET: 'TABLET'
  },
  HEADER_RESPONSE_MY_ACCOUNT_LABEL: 'actionAccount',
  SESSION_COOKIE_NAME: 'JSESSIONID',
  SEARCH_REDIRECT_STATUS: 'alt',
  REDIRECT_CODE: 307, // IE11 was not able to recognize 302
  DEFAULT_COOKIE_DOMAIN: '.falabella.com',
  SO_COM_PAGE_TITLE: 'Sodimac',
  F_COM_PAGE_TITLE: 'Falabella.com',
  META_SITE: {
    DESCRIPTION: {
      TOTTUS: {
        cl: {
          PDP:
            'Encuentra $product_name$ $brand_name$ con grandes ofertas en Tottus $country$. Elige el mejor precio para ti y aprovecha nuestras promociones online. $product_id$'
        },
        pe: {
          PDP:
            'Compra online $product_name$ de la marca $brand_name$ en Tottus $country$, haz la mejor elección para ti y aprovecha nuestras ofertas.'
        },
        co: {
          PDP:
            'Encuentra $product_name$ $brand_name$ con grandes ofertas en Tottus $country$. Elige el mejor precio para ti y aprovecha nuestras promociones online. $product_id$'
        },
        ar: {
          PDP:
            'Encuentra $product_name$ $brand_name$ con grandes ofertas en Tottus $country$. Elige el mejor precio para ti y aprovecha nuestras promociones online. $product_id$'
        }
      },
      SODIMAC: {
        cl: {
          PDP:
            'Compra $product_name$ de Sodimac en Falabella.com. Descubre sus características principales y adquiere la mejor opción para ti.'
        },
        pe: {
          PDP:
            'Compra a un costo accesible $product_name$, ingresa a nuestro sitio web y conoce más detalles de este producto disponible.'
        },
        co: {
          PDP:
            'Compra online $product_name$ en Sodimac $country$ los mejores productos de $brand_name$'
        },
        ar: {
          PDP:
            'Compra online $product_name$ en Sodimac $country$ los mejores productos de $brand_name$'
        }
      },
      HOMECENTER: {
        co: {
          PDP:
            'Compra online $product_name$ en Homecenter $country$ los mejores productos de $brand_name$'
        }
      },
      LINIO: {
        cl: {
          PDP: '$product_id$'
        },
        pe: {
          PDP:
            'Compra online $product_name$ de $brand_name$ en falabella.com conoce sus características y adquiere la mejor opción para ti.'
        },
        co: {
          PDP: '$product_id$'
        },
        ar: {
          PDP: '$product_id$'
        }
      },
      TIENDA: {
        cl: {
          PDP:
            '$brand_name$/$product_name$: Revisa sus características y modelos disponibles. Compra online aquí.'
        },
        pe: {
          PDP:
            'Compra online $product_name$ de $brand_name$ en falabella.com conoce sus características y adquiere la mejor opción para ti.'
        },
        co: {
          PDP:
            '$brand_name$/$product_name$: Revisa sus características y modelos disponibles. Compra online aquí.'
        },
        ar: {
          PDP:
            '$brand_name$/$product_name$: Revisa sus características y modelos disponibles. Compra online aquí.'
        }
      },
      SO_COM: {
        cl: {
          PDP:
            'Compra online $product_name$ en Sodimac – Homecenter los mejores productos y la mejor calidad de $category$ $brand_name$'
        },
        pe: {
          PDP:
            '$product_name$ a un precio increíble de venta exclusiva y de calidad en Sodimac Perú. Compra online $product_name$ y elige entre delivery y retiro en tienda. El stock es limitado.'
        }
      },
      TO_COM: {
        cl: {
          PDP:
            'Encuentra $product_name$ $brand_name$ con grandes ofertas en Tottus Chile. Elige el mejor precio para ti y aprovecha nuestras promociones online.'
        },
        pe: {
          PDP:
            'Encuentra $product_name$ $brand_name$ con grandes ofertas en Tottus Peru. Elige el mejor precio para ti y aprovecha nuestras promociones online.'
        }
      }
    },
    TITLE: {
      TOTTUS: {
        cl: {
          PDP: '$product_name$ | Tottus $country$'
        },
        pe: {
          PDP: '$product_name$ | Tottus $country$'
        },
        co: {
          PDP: '$product_name$ | Tottus $country$'
        },
        ar: {
          PDP: '$product_name$ | Tottus $country$'
        }
      },
      SODIMAC: {
        cl: {
          PDP: '$product_name$ | Sodimac - Falabella'
        },
        pe: {
          PDP: '$product_name$ | Sodimac Falabella'
        },
        co: {
          PDP: '$product_name$ | Sodimac'
        },
        ar: {
          PDP: '$product_name$ | Sodimac'
        }
      },
      SO_COM: {
        cl: {
          PDP: '$product_name$ | Sodimac $country$'
        },
        pe: {
          PDP: '$product_name$ | Sodimac $country$'
        },
        co: {
          PDP: '$product_name$ | Sodimac'
        },
        ar: {
          PDP: '$product_name$ | Sodimac'
        }
      },
      TO_COM: {
        cl: {
          PDP: '$product_name$ | Tottus $country$'
        },
        pe: {
          PDP: '$product_name$ | Tottus $country$'
        }
      },

      HOMECENTER: {
        co: {
          PDP: '$product_name$ | Homecenter'
        }
      },
      LINIO: {
        cl: {
          PDP: '$product_name$ $brand_name$ | Linio $country$'
        },
        pe: {
          PDP: '$product_name$ $brand_name$ | falabella.com'
        },
        co: {
          PDP: '$product_name$ $brand_name$ | Linio $country$'
        },
        ar: {
          PDP: '$product_name$ $brand_name$ | Linio $country$'
        }
      },
      TIENDA: {
        cl: {
          PDP: '$brand_name$/$product_name$ | Tienda Falabella'
        },
        pe: {
          PDP: '$product_name$ $brand_name$ | falabella.com'
        },
        co: {
          PDP: '$brand_name$/$product_name$ | Tienda Falabella'
        },
        ar: {
          PDP: '$brand_name$/$product_name$ | Tienda Falabella'
        }
      }
    }
  },
  META: {
    DESCRIPTION: {
      cl: {
        PDP:
          'Compra $product_name$ de $brand_name$ en Falabella $country$. Descubre sus características principales y adquiere la mejor opción para ti.',
        PLANS:
          'Llévate el celular $brand_name$ $product_name$ que más te guste con el plan perfecto para ti. Encuéntralo en Falabella $country$.'
      },
      pe: {
        PDP:
          'Compra online $product_name$ de $brand_name$ en falabella.com conoce sus características y adquiere la mejor opción para ti.',
        PLANS:
          'Elige el celular $brand_name$ $product_name$ con increíbles descuentos. Encuétralo en falabella.com'
      },
      co: {
        PDP:
          'Compra $product_name$ de $brand_name$ en Falabella $country$. Descubre sus características principales y adquiere la mejor opción para ti.',
        PLANS:
          'Llévate el celular $brand_name$ $product_name$ que más te guste con el plan perfecto para ti. Encuéntralo en Falabella $country$.'
      }
    },
    OG: {
      cl: {
        COUNTRY: 'CL',
        SITENAME: 'Falabella',
        TITLE: 'falabella.com',
        IMAGE: 'https://www.falabella.com/android-chrome-256x256.png',
        IMAGE_MKP:
          'https://images.falabella.com/v3/assets/blt7c5c2f2f888a7cc3/blt82dfb1aadb87635c/OG_Fcom.jpg',
        so_com: {
          COUNTRY: 'CL',
          SITENAME: 'Sodimac',
          TITLE: 'sodimac.com'
        },
        to_com: {
          COUNTRY: 'CL',
          SITENAME: 'Tottus',
          TITLE: 'tottus.cl'
        }
      },
      co: {
        COUNTRY: 'CO',
        SITENAME: 'FalabellaCO',
        TITLE: 'falabella.com',
        IMAGE: 'https://www.falabella.com/android-chrome-256x256.png',
        IMAGE_MKP:
          'https://images.falabella.com/v3/assets/blt7c5c2f2f888a7cc3/blt82dfb1aadb87635c/OG_Fcom.jpg'
      },
      ar: {
        COUNTRY: 'AR',
        SITENAME: 'FalabellaAR',
        TITLE: 'falabella.com',
        IMAGE: 'https://www.falabella.com/android-chrome-256x256.png',
        IMAGE_MKP:
          'https://images.falabella.com/v3/assets/blt7c5c2f2f888a7cc3/blt82dfb1aadb87635c/OG_Fcom.jpg'
      },
      pe: {
        COUNTRY: 'PE',
        SITENAME: 'FalabellaPE',
        TITLE: 'falabella.com',
        IMAGE: 'https://www.falabella.com/android-chrome-256x256.png',
        IMAGE_MKP:
          'https://images.falabella.com/v3/assets/blt7c5c2f2f888a7cc3/blt82dfb1aadb87635c/OG_Fcom.jpg',
        so_com: {
          COUNTRY: 'PE',
          SITENAME: 'Sodimac',
          TITLE: 'sodimac.com'
        },
        to_com: {
          COUNTRY: 'PE',
          SITENAME: 'Tottus',
          TITLE: 'tottus.com.pe'
        }
      }
    },
    ORGANISATION: {
      so_com: 'Sodimac',
      to_com: 'Tottus'
    }
  },
  PDP: {
    META: {
      CO: {
        TITLE: '$product_name$ $brand_name$ | falabella.com',
        DESCRIPTION:
          'Encuentra $product_name$ $brand_name$ a precios espectaculares, elige el modelo perfecto para ti y aprovecha nuestras ofertas online.'
      }
    }
  },
  COUNTRY: {
    cl: 'Chile',
    pe: 'Perú',
    co: 'Colombia',
    ar: 'Argentina'
  },
  PAGE_TITLE: {
    cl: 'falabella.com - Mejor Compra Online',
    co: 'falabella.com - Bienvenidos a Nuestra Tienda Online',
    ar: 'Falabella Argentina',
    pe: 'falabella.com - Bienvenidos a Nuestra Tienda Online'
  },
  TRACING_HEADERS: [
    'x-request-id',
    'x-b3-traceid',
    'x-b3-spanid',
    'x-b3-parentspanid',
    'x-b3-sampled',
    'x-b3-flags',
    'x-ot-span-context',
    'referer'
  ],
  ERROR_MESSAGE_TEXT: 'Product Search Failed',
  DEFAULT_LAYOUT_TYPE: 'HARDLINE',
  PRODUCT_CONSTANTS: {
    HARDLINE: 'HARDLINE',
    SOFTLINE: 'SOFTLINE',
    CONNECT: 'CONNECT'
  },
  HTTP_RES_CODE: {
    FOUND: 302,
    TEMP_REDIRECT: 307,
    PERM_REDIRECT: 301,
    NOT_FOUND_CODE: 404
  },
  DEVICE_DESKTOP: 'desktop',
  DEVICE_MOBILE: 'mobile',
  DEVICE_TABLET: 'tablet',
  PLATFORM_TYPE: {
    ANDROID: 'android',
    IOS: 'ios'
  },
  LAYOUT_2_GRID: '2_GRID',
  LAYOUT_3_GRID: '3_GRID',
  LAYOUT_4_GRID: '4_GRID',
  LAYOUT_1_GRID: '1_GRID',
  LAYOUT_LIST: 'LIST',
  SPANISH_DAYS_OF_WEEK: {
    1: 'Lun',
    2: 'Mar',
    3: 'Mié',
    4: 'Jue',
    5: 'Vie',
    6: 'Sáb',
    0: 'Dom'
  },
  SPANISH_MONTHS_MAPPER: {
    0: 'Enero',
    1: 'Febrero',
    2: 'Marzo',
    3: 'Abril',
    4: 'Mayo',
    5: 'Junio',
    6: 'Julio',
    7: 'Agosto',
    8: 'Septiembre',
    9: 'Octubre',
    10: 'Noviembre',
    11: 'Diciembre'
  },
  SPANISH_MONTHS_MAPPING: {
    LUN: 'Lun',
    MAR: 'Mar',
    MIE: 'Mié',
    JUE: 'Jue',
    VIE: 'Vie',
    SAB: 'Sáb',
    DOM: 'Dom'
  },
  AVAILABILITY_OUTOFSTOCK: 'OutOfStock',
  AVAILABILITY_INSTOCK: 'InStock',
  AVAILABILITY_HOMEDELIVERY: 'HomeDelivery',
  AVAILABILITY_PICKUPINSTORE: 'PickupInStore',
  AVAILABILITY_SITETOSTORE: 'SiteToStore',
  AVAILABILITY_PURCHASEONLINE: 'PurchaseOnline',
  AVAILABILITY_NETWORKSTOCK: 'Network',
  PRODUCT_TYPE: {
    SOFT_GOOD: 'SOFT_GOOD',
    GIFTCARD: 'GIFTCARD'
  },
  OUT_OF_STOCK: 'OUT_OF_STOCK',
  NOT_FOUND: 'NOT_FOUND',
  DEFAULT_CMR_INSTALLMENTS: 10,
  DEFAULT_CMR_BANK_NAME: 'unselected',
  DEFAULT_CMR_BANK_NAME_AR: 'CMR',
  PAGES: {
    PDP: 'PDP',
    PLP: 'PLP',
    SLP: 'SLP',
    PLANS: 'PLANS',
    CART: 'CART',
    POD: 'POD'
  },
  APP_COMP_ERR_PAGES: {
    'falabella-cl': 'https://www.falabella.com/error/404.html',
    'falabella-pe': 'https://www.falabella.com.pe/falabella-pe/error/404.html',
    'falabella-co': 'https://www.falabella.com.co/falabella-co/error/404.html'
  },
  YOUTUBE_THUMBNAIL_URL: 'https://img.youtube.com/vi/{id}/hqdefault.jpg',
  VIEW_TEMPLATE: {
    TYPE_A: 'TYPE_A',
    TYPE_C: 'TYPE_C'
  },
  DIGITAL_DATA_STORAGE_NAME: 'analyticsInfo',
  WARRANTY_OPTIONS_MIN_ITEM_COUNT_FOR_DROPDOWN: 5,
  CURRENCY: {
    CL: 'CLP',
    CO: 'COP',
    AR: 'ARS',
    PE: 'PEN'
  },
  MAX_CART_PRODUCT: 999,
  CONNECT_SPECIFICATIONS: [
    { specId: 'camera', value: 'Cámara principal', text: 'Cámara Trasera' },
    { specId: 'camera', value: 'Cámara frontal', text: 'Cámara Frontal' },
    { specId: 'memory', value: 'Memoria interna', text: 'Memoria Interna' },
    { specId: 'battery', value: 'Capacidad batería (mAh)', text: 'Batería' },
    { specId: 'core', value: 'Velocidad del procesador', text: 'Procesador' }
  ],
  CONNECT_SPECIFICATIONS_PRIORITY: [
    {
      specId: 'camera',
      text: ['Cámara posterior', 'Cámara frontal'],
      label: ['Cámara Trasera', 'Cámara Frontal']
    },
    { specId: 'memory', text: 'Memoria interna', label: 'Memoria Interna' },
    { specId: 'battery', text: 'Capacidad batería (mAh)', label: 'Batería' },
    { specId: 'core', text: 'Velocidad del procesador', label: 'Procesador' },
    {
      specId: 'screen-size',
      text: 'Tamaño de la pantalla',
      label: 'Tamaño de la pantalla'
    },
    { specId: 'os', text: 'Sistema operativo', label: 'Sistema operativo' },
    {
      specId: 'water-proof',
      text: 'Resistente al agua',
      label: 'Resistencia al agua'
    },
    {
      specId: 'core',
      text: 'Núcleos del procesador',
      label: 'Núcleos del procesador'
    },
    {
      specId: 'memory',
      text: 'Memoria externa incluida',
      label: 'Memoria externa incluida'
    },
    { specId: 'camera', text: 'Flash frontal', label: 'Flash frontal' },
    { specId: 'screen', text: 'Tipo de pantalla', label: 'Tipo de pantalla' },
    {
      specId: 'core',
      text: 'Marca y modelo del procesador',
      label: 'Marca y modelo del procesador'
    },
    { specId: 'memory', text: 'Memoria RAM', label: 'Memoria RAM' },
    {
      specId: 'memory',
      text: 'Memoria expandible',
      label: 'Memoria expandible'
    },
    { specId: 'sim', text: 'Dual SIM', label: 'Dual SIM' },
    { specId: 'gps', text: 'GPS integrado', label: 'GPS integrado' },
    { specId: 'connectivity', text: 'Conectividad', label: 'Conectividad' },
    { specId: 'finger', text: 'Lector de huella', label: 'Lector de huella' },
    { specId: 'made-in', text: 'Hecho en', label: 'Hecho en' },
    { specId: 'weight', text: 'Peso', label: 'Peso' }
  ],
  SESSION_CNF_KEY_NAME: 'FA_SESSION_CNF',
  SESSION_CNF_TTL: 10000,
  ADD_TO_CART_WARNING_MSG_LIMIT: 3,
  MODEL_CHARACTERISTICS_SPECIFICATION_ID: [
    'Medidas del modelo',
    'Medidas de la modelo'
  ],
  FALABELLA_MARKET_PLACE_ID: 'FALABELLA',
  FALABELLA_REGEX: /^falabella$/i,
  SODIMAC_REGEX: /^sodimac$/i,
  HOMECENTER_REGEX: /^homecenter$/i,
  TOTUS_REGEX: /^tottus$/i,
  TOTTUS_REGEX: /^tottus$/i,
  LINIO_REGEX: /^linio$/i,
  LINIO_TIENDA_REGEX: /(linio|tienda)/,
  FIRST_PARTY_SELLER_REGEX: /(tottus|sodimac|linio|falabella|homecenter)/,
  FREE_PICKUP_STORES: /(tottus|sodimac|linio|falabella|homecenter|plaza)/,
  NEXT_DAY_DELIVERY_REGEX: /(Llega mañana)/,
  SAME_DAY_HD_CC_REGEX: /(Llega hoy|Retira hoy)/,
  SHIPPING_CC_REGEX: /(Retira hoy|Retira mañana)/,
  STORE_PLACEHOLDER: '{{tienda}}',
  STORES: {
    sodimac: 'sodimac',
    tottus: 'tottus',
    linio: 'linio',
    tienda: 'tienda',
    homecenter: 'homecenter',
    so_com: 'so_com',
    to_com: 'to_com'
  },
  DECIMAL_SEPARATOR: {
    cl: ',',
    co: ',',
    ar: ',',
    pe: '.'
  },
  DOT_SEPARATOR: {
    cl: '.',
    co: '.',
    ar: '.',
    pe: ','
  },
  DY_ID: {
    staging: {
      cl: 8772169,
      pe: 8774134,
      co: 8774132
    },
    beta: {
      cl: 8772169,
      pe: 8774134,
      co: 8774132
      //  Since there is no property in FACO and FAPE beta env we will point to production.
    },
    www: {
      cl: 8772169,
      pe: 8774134,
      co: 8774132
    }
  },
  GTM_ID: {
    beta: {
      cl: 'GTM-NJX6QWJ',
      ar: 'GTM-K53B7WT',
      pe: 'GTM-T29WRLG',
      co: 'GTM-PQRB5NV',
      sodimac: {
        cl: 'GTM-WGGFJP',
        pe: 'GTM-5M8VN5',
        co: 'GTM-PQRB5NV'
      }
    },
    www: {
      cl: 'GTM-NJX6QWJ',
      ar: 'GTM-K53B7WT',
      pe: 'GTM-T29WRLG',
      co: 'GTM-PQRB5NV',
      sodimac: {
        cl: 'GTM-WGGFJP',
        pe: 'GTM-5M8VN5',
        co: 'GTM-PQRB5NV'
      }
    },
    staging: {
      cl: 'GTM-NJX6QWJ',
      ar: 'GTM-K53B7WT',
      pe: 'GTM-T29WRLG',
      co: 'GTM-PQRB5NV',
      sodimac: {
        cl: 'GTM-WGGFJP',
        pe: 'GTM-5M8VN5',
        co: 'GTM-PQRB5NV'
      }
    }
  },
  GTM_BASE: 'https://www.googletagmanager.com',
  MEDALLIA:
    'https://resources.digital-cloud.medallia.com/wdcus/55961/onsite/embed.js',
  GOOGLE_MAP: 'https://maps.googleapis.com/maps/api/js?',
  DIGITAL_DATA_MANAGER_PDP_SCRIPT: {
    dev:
      'https://assets-staging.falabella.com/v3/assets/blt07d653b53a07a055/blte2189fa43abf355b/pdp.cjs',
    staging:
      'https://assets-staging.falabella.com/v3/assets/blt7c5c2f2f888a7cc3/blt9dc4ff987f3c1540/pdp.cjs',
    beta:
      'https://assets-uat.falabella.com/v3/assets/blt7c5c2f2f888a7cc3/blt3fa4d6f2031ea63e/pdp.cjs',
    www:
      'https://assets.falabella.com/v3/assets/blt7c5c2f2f888a7cc3/blt19c4fcb2d0d5ff0a/pdp.cjs'
  },
  DIGITAL_DATA_MANAGER_NO_RESULT_SCRIPT: {
    dev:
      'https://assets-staging.falabella.com/v3/assets/blt07d653b53a07a055/blt5bfd70100db5c053/noResult.cjs',
    staging:
      'https://assets-staging.falabella.com/v3/assets/blt7c5c2f2f888a7cc3/blta2518e2baacf2b83/noResult.cjs',
    beta:
      'https://assets-uat.falabella.com/v3/assets/blt7c5c2f2f888a7cc3/bltb3b1a8fe31edced4/noResult.cjs',
    www:
      'https://assets.falabella.com/v3/assets/blt7c5c2f2f888a7cc3/bltc1b0c6ffbc670416/noResult.cjs'
  },
  VWO_SCRIPT: {
    cl:
      'https://assets.falabella.com/v3/assets/blt7c5c2f2f888a7cc3/blt3ae1beb67525ed26/vwo.js',
    pe:
      'https://assets.contentstack.io/v3/assets/blt7c5c2f2f888a7cc3/bltf1762da3c97b64e4/vwo.js',
    co:
      'https://assets.contentstack.io/v3/assets/blt7c5c2f2f888a7cc3/bltf0b1376d26dd28cc/vwo.js'
  },
  guidedSellingColors: {
    amarillo: '#eec12b',
    azul: '#0736c4',
    blanco: '#ffffff',
    burdeo: '#440000',
    cafe: '#8e5b3e',
    gris: '#c6b8b8',
    marron: '#8e5b3e',
    morado: '#77337f',
    musgo: '#1c9e43',
    naranja: '#ef992f',
    naranjo: '#ef992f',
    negro: '#000000',
    plomo: '#c6b8b8',
    rojo: '#ce0c0c',
    rosa: '#f276d5',
    rosado: '#f276d5',
    salmon: '#ef992f',
    verde: '#1c9e43',
    violeta: '#d225db',
    sincolor: 'Sin color'
  },
  LS_HOME_DELIVERY_KEY: 'AVAILABILTY_HD',
  LS_STORE_PICKUP_KEY: 'AVAILABILTY_CC',
  COOKIE_HOME_DELIVERY_KEY: 'availabilityHD',
  LEGAL_BANNER: 'isCookieLegalBannerSelected',
  COOKIE_STORE_PICKUP_KEY: 'availabilityCC',
  DEFAULT_ZONES: 'AFZone',
  ANDES_DEF_ZONE: {
    politicalId: '15c37b0b-a392-41a9-8b3b-978376c700d5',
    priceGroupId: '96',
    zones: ['ZL_CERRILOS', 'LOSC', '130617', '13'],
    oldZones: ['LOSC', '130617', '13'],
    name: 'Seleccion ubicacion'
  },
  DEF_ZONE: {
    cl: {
      staging: {
        politicalId: '4856187d-c035-45ac-9f4e-9290b9e9f85f',
        priceGroupId: '96',
        zones: ['ZL_CERRILOS', 'LOSC', '130617', '13'],
        name: 'Seleccion ubicacion'
      },
      prod: {
        politicalId: '15c37b0b-a392-41a9-8b3b-978376c700d5',
        priceGroupId: '96',
        zones: ['ZL_CERRILOS', 'LOSC', '130617', '13'],
        name: 'Seleccion ubicacion'
      },
      beta: {
        politicalId: '15c37b0b-a392-41a9-8b3b-978376c700d5',
        priceGroupId: '96',
        zones: ['ZL_CERRILOS', 'LOSC', '130617', '13'],
        name: 'Seleccion ubicacion'
      }
    },
    pe: {
      staging: {
        politicalId: 'c6b7a50c-60f3-4687-91d0-9461d538812a',
        priceGroupId: '2',
        zones: ['150000'],
        name: 'Seleccion ubicacion'
      },
      prod: {
        politicalId: '799c102f-9b4c-44be-a421-23e366a63b82',
        priceGroupId: '2',
        zones: ['150000', '180000'],
        name: 'Seleccion ubicacion'
      },
      beta: {
        politicalId: '799c102f-9b4c-44be-a421-23e366a63b82',
        priceGroupId: '2',
        zones: ['150000', '180000'],
        name: 'Seleccion ubicacion'
      }
    },
    co: {
      staging: {
        politicalId: '59a6aec4-fc51-4f3b-ab5e-62fd7a1076c0', // TBD
        priceGroupId: '19',
        zones: ['14'],
        name: 'Seleccion ubicacion'
      },
      prod: {
        politicalId: '764bc3a7-0a78-4409-b2b5-a92606b325d4',
        priceGroupId: '19',
        zones: ['14'],
        name: 'Seleccion ubicacion'
      },
      beta: {
        politicalId: '764bc3a7-0a78-4409-b2b5-a92606b325d4',
        priceGroupId: '19',
        zones: ['14'],
        name: 'Seleccion ubicacion'
      }
    }
  },
  PLAN_HELP_NUMBER: '600 390 4100',
  STORE_MODAL_REGION_DATA: 'storeModalRegion',
  STORE_AVAILABILITY_MODAL_WIDTH: {
    screen1: 800,
    screen2: 500,
    screen3: 800
  },
  STORE_AVAILABILITY_MODAL_HEIGHT: {
    screen1: '100%',
    screen2: 531,
    screen3: 575
  },
  STOCK_CATEGORY_CONFIG: {
    EXACT_UNITS: 'EXACT_UNITS',
    RANGE: 'RANGE'
  },
  ATC_SESSION_EXPIRED_CODE: 'CHECKOUT_INVALID_SESSION',
  CMR_LIST_OPTIONS: {
    cl: [
      {
        title: 'Con tarjeta CMR',
        divideFactor: 200,
        logo: icons.cmrTarjetaIcon
      },
      {
        title: 'Con Débito Banco Falabella',
        divideFactor: 500,
        logo: icons.bancoIcon
      },
      {
        title: 'Con Cuenta Fpay',
        divideFactor: 500,
        logo: icons.fpayIcon
      },
      {
        title: 'Con tarjeta de crédito',
        divideFactor: 750,
        logo: icons.creditoIcon
      },
      {
        title: 'Con tarjeta de débito',
        divideFactor: 750,
        logo: icons.debitoIcon
      }
    ],
    co: [
      {
        title: 'Con tarjeta CMR',
        divideFactor: 1000,
        logo: icons.cardIconsFaco.cmr
      },
      {
        title: 'Con Débito Banco Falabella',
        divideFactor: 2000,
        logo: icons.cardIconsFaco.banco
      },
      {
        title: 'Con tarjeta de crédito',
        divideFactor: 4000,
        logo: icons.creditoIcon
      },
      {
        title: 'Con tarjeta de débito',
        divideFactor: 4000,
        logo: icons.debitoIcon
      }
    ]
  },
  SPONSORED_PRODUCTS_CAROUSEL_HEADING: 'También podría interesarte',
  SPONSORED_PRODUCTS_CAROUSEL_SUB_HEADING: 'Patrocinado',
  LOGOMAPPINGS: {
    'icons.cmrTarjetaIcon': icons.cmrTarjetaIcon,
    'cons.bancoIcon': icons.bancoIcon,
    'icons.fpayIcon': icons.fpayIcon,
    'icons.creditoIcon': icons.creditoIcon,
    'icons.debitoIcon': icons.debitoIcon
  },
  PRICE_TYPE: {
    cmr: 'cmrPrice',
    event: 'eventPrice',
    internet: 'internetPrice',
    normal: '"normalPrice"'
  },
  ML_METRICS: {
    LISTWISE_DATA: {
      LIST_WISE: 'LIST_WISE_DATA',
      ALREADY_ADDED: 'Already added in',
      DELETED_FROM: 'Deleted from'
    },
    METRICS_DATA: {
      WITH_ML: 'Product with mis listas',
      WITHOUT_ML: 'Without mis listas',
      ALREADY_ADDED: 'Product already added',
      NOT_ADDED: 'Product not added',
      CLICK_ML: 'Click mis listas',
      NOT_CLICK_ML: 'Not click mis listas',
      CLICK_CREATE_LIST: 'Click crear nueva lista',
      NOT_CLICK_CREATE_LIST: 'Do not click crear nueva lista',
      SAVE_NEW_LIST: 'Guardar nueva lista',
      NOT_SAVE_NEW_LIST: 'Do not save nueva lista',
      SAVE_IN_MYFAVS: 'Save in favoritos',
      DO_NOT_SAVE_MYFAVS: 'Do not save in favoritos ',
      NOT_SAVE_IN_L2: 'Do not save in Ln 2',
      SAVE_IN_L2: 'Save in Ln 2',
      NOT_SAVE_IN_L3: 'Do not save in Ln 3',
      SAVE_IN_L3: 'Save in Ln 3',
      NOT_SAVE_IN_L4: 'Do not save in Ln 4',
      SAVE_IN_L4: 'Save in Ln 4',
      NOT_SAVE_IN_L5: 'Do not save in Ln 5',
      SAVE_IN_L5: 'Save in Ln 5'
    }
  },
  COLOR_SWATCH_METRICS: {
    METRICS_DATA: {
      PURCHASE_COLOR_SWATCH: 'Purchase',
      NOT_PURCHASE_COLOR_SWATCH: 'Not Purchase'
    }
  },
  REACONDITIONED: {
    Nuevo: 'NUEVO',
    'Sin uso con detalle estético': 'REACONDICIONADO',
    'Open Box': 'OPEN BOX',
    'Reacondicionado excelente (A)': 'REACONDICIONADO',
    'Reacondicionado detalle estético (B)': 'REACONDICIONADO',
    'Segunda mano nuevo con etiqueta': 'REACONDICIONADO',
    'Segunda mano como nuevo': 'REACONDICIONADO',
    'Segunda mano con detalles': 'REACONDICIONADO'
  },
  REVIEW_SECTION_HEADING: 'Comentarios de este producto',
  IMAGE_URlS: {
    cl:
      'https://imagedelivery.net/4fYuQyy-r8_rpBpcY7lH_A/FalabellaCL/NoImage/public',
    pe:
      'https://imagedelivery.net/4fYuQyy-r8_rpBpcY7lH_A/FalabellaPEAndCO/NoImage/public',
    co:
      'https://imagedelivery.net/4fYuQyy-r8_rpBpcY7lH_A/FalabellaPEAndCO/NoImage/public',
    tottus:
      'https://imagedelivery.net/4fYuQyy-r8_rpBpcY7lH_A/Tottus/defaultImage/public',
    sodimac:
      'https://imagedelivery.net/4fYuQyy-r8_rpBpcY7lH_A/Sodimac/NonAndes/espanol_default/public'
  },
  SODIMAC_STANDALONE_SITE_REGEX: /sodimac\.(cl|com\.pe)/,
  TOTTUS_STANDALONE_SITE_REGEX: /tottus\.(cl|com\.pe)/,
  standAlones: [
    {
      domain: 'sodimac',
      exp: 'so_com',
      regex: /sodimac\.(cl|com\.pe)/,
      name: 'Sodimac'
    },
    {
      domain: 'tottus',
      exp: 'to_com',
      regex: /tottus\.(cl|com\.pe)/,
      name: 'Tottus'
    }
  ],

  PRODUCT: 'product',
  ARTICULO: 'articulo',
  sites: {
    sodimac: 'SODIMAC',
    tottus: 'TOTTUS',
    linio: 'LINIO',
    tienda: 'TIENDA',
    homecenter: 'HOMECENTER',
    so_com: 'SO_COM',
    to_com: 'FAZIL_MKP',
    marketplace: 'FA_MKP'
  },
  subdomains: {
    sodimac: 'sodimac',
    tottus: 'tottus',
    homecenter: 'homecenter'
  },
  SposonsoredDisplayPosition: {
    desktop: 4,
    mobile: 5
  },
  CART_DEFAULT_MESSAGE: 'Hubo un error al agregar el producto al carro',
  GENDER_ATTRIBUTE: 'Género',
  IS_USER_OVER_18: 'isUserOver18'
};

export default constants;
