import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withTheme } from '@digital-retail/falabella-ui-cross-components';
import StoreListDetailsStyles from './StoreListDetails.style';
import _ from '../../../../../utils/LodashImports';
import noop from '../../../../../utils/noop';
import constants from '../../../../../config/constants';
import { capitalizeString } from '../../../../../utils/jsUtils';

const StoreListDetails = ({
  pickupInStoreData,
  setSelectedStoreId,
  selectedStoreId,
  textDictionary,
  theme,
  view
}) => {
  const themedStoresListStyles = StoreListDetailsStyles(theme);
  const isListView = view === 'list';

  return (
    <div className={classNames('lists-container', { 'list-view': isListView })}>
      <div className={classNames('store-box', { 'list-view': isListView })}>
        {pickupInStoreData.map((d) => {
          const addressLine1 = _.get(d, 'address.addressLine1', '');
          const municipalName = _.get(d, 'address.municipalName', '');
          const operator = _.get(d, 'operator', 'falabella').toLowerCase();
          const workingDays = _.get(d, 'workingDays', '');
          const storeId = _.get(d, 'storeId', '');
          const dateLabel = _.get(
            textDictionary,
            'DATE_LABEL',
            'Desde el <<date>> de <<month>>'
          );
          const freeLabel = _.get(textDictionary, 'FREE_LABEL', 'Gratis');
          const openLabel = _.get(textDictionary, 'OPEN_LABEL', 'Abierto');
          const date = _.get(d, 'earliestAvailableSlot.slotDate.date', '');
          const month = _.get(d, 'earliestAvailableSlot.slotDate.month', '');
          const replacedDateLabel = dateLabel
            .replace('<<date>>', date)
            .replace('<<month>>', month);
          const is1pSeller = constants.FREE_PICKUP_STORES.test(operator);
          return (
            <React.Fragment key={`testId-${storeId}`}>
              <div
                id={`testId-${storeId}`}
                role="button"
                tabIndex={0}
                onKeyDown={noop}
                onClick={() => setSelectedStoreId(storeId)}
                className={classNames('details-container', {
                  selected: selectedStoreId === storeId
                })}
              >
                <input
                  type="radio"
                  readOnly
                  className="selection-radio"
                  id="delivery-options-selection"
                  checked={selectedStoreId === storeId}
                />
                <div
                  className={classNames('store-content', {
                    'list-view': isListView,
                    selected: selectedStoreId === storeId
                  })}
                >
                  <div className="name-address">
                    <div className="store-name">
                      <p>{capitalizeString(d.storeName)}</p>
                      <p>
                        {capitalizeString(addressLine1)},{' '}
                        {capitalizeString(municipalName)}
                      </p>
                    </div>
                    <span className={`icon ${operator} hide-in-mobile`} />
                  </div>
                  {workingDays && (
                    <span className="working-hours flex">
                      <span className="icon clock-icon" />
                      <span className="green-bold">{openLabel}</span>
                      <p className="text">{workingDays}</p>
                    </span>
                  )}
                  {is1pSeller && (
                    <div className="delivery-dates flex">
                      <p>{replacedDateLabel}</p>
                      <p className="green-bold">{freeLabel}</p>
                    </div>
                  )}
                </div>
              </div>
              <div className="separator" />
            </React.Fragment>
          );
        })}
      </div>
      <style jsx>{themedStoresListStyles}</style>
    </div>
  );
};
StoreListDetails.propTypes = {
  pickupInStoreData: PropTypes.array,
  setSelectedStoreId: PropTypes.func.isRequired,
  selectedStoreId: PropTypes.any.isRequired,
  textDictionary: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  view: PropTypes.string.isRequired
};

StoreListDetails.defaultProps = {
  pickupInStoreData: []
};

export default withTheme(StoreListDetails);
export { StoreListDetails };
