import { svgToDataUri } from '../../../utils/styles/svgMixins';
import etamMarker from '../../../static/styles/svg/etamMarker';
import chilexpressMarker from '../../../static/styles/svg/chilexpressMarker';
import metroMarker from '../../../static/styles/svg/metroMarker';
import blueExpressMarker from '../../../static/styles/svg/blueExpressMarker';
import tottusMarker from '../../../static/styles/svg/tottusMarker';
import sodimacMarker from '../../../static/styles/svg/sodimacMarker';
import plazaMarker from '../../../static/styles/svg/plazaMarker';
import falabellaMarker from '../../../static/styles/svg/falabellaMarker';
import sybillaMarker from '../../../static/styles/svg/sybillaMarker';
import americaninoMarker from '../../../static/styles/svg/americaninoMarker';
import clarksMarker from '../../../static/styles/svg/clarksMarker';
import mangoMarker from '../../../static/styles/svg/mangoMarker';
import aldoMarker from '../../../static/styles/svg/aldoMarker';
import cartersMarker from '../../../static/styles/svg/cartersMarker';
import fcomMarker from '../../../static/styles/svg/fcomMarker';
import constants from '../../../config/constants';

const getStoreIcon = (store) => {
  switch (store) {
    case 'tottus':
      return tottusMarker;

    case 'sodimac':
      return sodimacMarker;

    case 'falabella':
      return falabellaMarker;

    case 'plaza':
      return plazaMarker;

    case 'chilexpress':
      return chilexpressMarker;

    case 'bluexpress':
      return blueExpressMarker;

    case 'metro':
      return metroMarker;

    case 'etam':
      return etamMarker;

    case 'sybilla':
      return sybillaMarker;

    case 'americanino':
      return americaninoMarker;

    case 'Clarks':
      return clarksMarker;

    case 'mango':
      return mangoMarker;

    case 'aldo':
      return aldoMarker;

    case 'carters':
      return cartersMarker;

    default:
      return fcomMarker;
  }
};

export const formatSlotsForMap = (data, selectedStoreId) =>
  data.map(({ latitude, longitude, operator, storeId, storeName }) => {
    return {
      longitude: Number(longitude),
      latitude: Number(latitude),
      draggable: false,
      icon: svgToDataUri(getStoreIcon(operator.toLowerCase())),
      selected: selectedStoreId === storeId,
      id: storeId,
      storeName
    };
  });

export const calculateHeightWidth = (deviceType, screen, type) => {
  if (deviceType === 'desktop') {
    if (type === constants.AVAILABILITY_PICKUPINSTORE) {
      if (screen === 'screen3') return { width: 500, height: 700 };
      if (screen === 'screen2') return { width: 500, height: 513 };
    }
    return { width: 500, height: '100%' };
  }
  return { width: '100vw', height: '100vh' };
};
