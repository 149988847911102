/* eslint-disable no-console */
import _ from 'lodash';
import httpService from '../../../utils/httpService';
import { getCommerceHeaders } from '../../../utils/tenantHelper';
import apiConfig from '../../../config/api/apiConfig';

const timeZones = {
  cl: 'America/Santiago',
  pe: 'America/Lima',
  co: 'America/Bogota'
};

const MONTHS = {
  enero: '01',
  en: '01',
  ene: '01',
  febrero: '02',
  feb: '02',
  marzo: '03',
  mar: '03',
  abril: '04',
  abr: '04',
  mayo: '05',
  may: '05',
  junio: '06',
  jun: '06',
  julio: '07',
  jul: '07',
  agosto: '08',
  ag: '08',
  ago: '08',
  septiembre: '09',
  set: '09',
  sep: '09',
  sept: '09',
  octubre: '10',
  oct: '10',
  noviembre: '11',
  nov: '11',
  diciembre: '12',
  dic: '12'
};

const pad = (d) => {
  return d < 10 ? `0${d.toString()}` : d.toString();
};

const getLocaleTime = (regionCode) => {
  const requiredTimezone = _.get(timeZones, `${regionCode}`, '');
  if (requiredTimezone) {
    try {
      return new Date().toLocaleString('en-US', { timeZone: requiredTimezone });
    } catch (e) {
      return new Date().toLocaleString('en-US');
    }
  }
  return new Date().toLocaleString('en-US');
};

const processHomeDeliverySlots = (
  homeDeliverySlots,
  homeDeliveryDateRangeSlots,
  regionCode,
  isFreeShippingApplicable
) => {
  const currentLocaleTime = getLocaleTime(regionCode);
  const currentDate = new Date(currentLocaleTime).getDate();
  const currentHour = new Date(currentLocaleTime).getHours();
  const currentTime = new Date(currentLocaleTime).getTime();
  const nextDate = new Date(currentTime + 24 * 3600 * 1000).getDate();
  const result = [];
  let i;
  for (i = 0; i < homeDeliverySlots.length; i += 1) {
    const slot = homeDeliverySlots[i];
    const customSlots = _.get(slot, 'customTimeSlots', []);
    if (customSlots.length === 0) {
      // eslint-disable-next-line no-continue
      continue;
    }
    const date = Number(_.get(slot, 'slotDate.date', 0));
    const month = _.get(slot, 'slotDate.month', '').toLowerCase();
    const price = _.get(slot, 'customTimeSlots[0].cost', undefined);
    if (
      date === currentDate &&
      currentHour < 13 &&
      (isFreeShippingApplicable || price !== undefined)
    ) {
      result.push({
        title: 'Llega hoy',
        price: isFreeShippingApplicable ? 'Gratis' : price,
        date: pad(date),
        month: MONTHS[month],
        show: true,
        isDateRange: false
      });
      break;
    } else if (
      date === currentDate &&
      currentHour >= 13 &&
      (isFreeShippingApplicable || price !== undefined)
    ) {
      result.push({
        title: 'Llega mañana',
        price: isFreeShippingApplicable ? 'Gratis' : price,
        date: pad(date),
        month: MONTHS[month],
        show: true,
        isDateRange: false
      });
      break;
    } else if (
      date === nextDate &&
      (isFreeShippingApplicable || price !== undefined)
    ) {
      result.push({
        title: 'Llega mañana',
        price: isFreeShippingApplicable ? 'Gratis' : price,
        date: pad(date),
        month: MONTHS[month],
        show: true,
        isDateRange: false
      });
      break;
    } else if (
      date !== 0 &&
      (isFreeShippingApplicable || price !== undefined) &&
      month !== ''
    ) {
      result.push({
        title: `Envio a domicilio a partir del ${date}/${MONTHS[month]}`,
        price: isFreeShippingApplicable ? 'Gratis' : price,
        date: pad(date),
        month: MONTHS[month],
        show: false,
        isDateRange: false
      });
      break;
    }
  }

  if (homeDeliveryDateRangeSlots.length > 0) {
    const dateRangePrice = _.get(
      homeDeliveryDateRangeSlots,
      '[0].cost',
      undefined
    );

    const description = _.get(
      homeDeliveryDateRangeSlots,
      '[0].description',
      ''
    );

    const dateRe = /[\d]+/g;
    const dateArray = description.match(dateRe) || [];
    let dateRangeDay = '';
    let dateRangeMonth = '';
    if (dateArray && Array.isArray(dateArray) && dateArray.length > 0) {
      [dateRangeDay, dateRangeMonth] = dateArray;
      dateRangeMonth = Number(dateRangeMonth);
    }

    if (isFreeShippingApplicable || dateRangePrice !== undefined) {
      result.push({
        title: 'Envío en rango de fechas',
        price: isFreeShippingApplicable ? 'Gratis' : dateRangePrice,
        date: dateRangeDay,
        month: dateRangeMonth,
        show: true,
        isDateRange: true
      });
    }
  }

  if (homeDeliverySlots.length > 0 && isFreeShippingApplicable) {
    result.push({
      title: 'Envío en fecha programada',
      price: 'Gratis',
      date: '',
      month: '',
      show: true,
      isDateRange: false
    });
    return result;
  }

  const regex = /[\d.,]+/g;

  const convertToNumber = (value) => {
    const numericValue = parseFloat(
      value.replace(/[$,]/g, '').replace(/S\//g, '')
    );
    return Number.isNaN(numericValue) ? null : numericValue;
  };

  const costArray = homeDeliverySlots.flatMap((slot) =>
    (slot.customTimeSlots || []).flatMap((timeSlot) => {
      const cost = timeSlot.cost || '';
      const normalisedCost = cost.match(regex);
      return normalisedCost && normalisedCost.length > 0
        ? { value: convertToNumber(normalisedCost[0]), original: cost }
        : [];
    })
  );

  if (costArray.length > 0) {
    const normalizedCosts = costArray.map((item) => item.value);

    const minCost = Math.min(...normalizedCosts);
    const maxCost = Math.max(...normalizedCosts);

    const foundMinOriginal = costArray.find((cost) => cost.value === minCost);
    const minOriginal = foundMinOriginal
      ? foundMinOriginal.original
      : costArray[0].original;

    const foundMaxOriginal = costArray.find((cost) => cost.value === maxCost);
    const maxOriginal = foundMaxOriginal
      ? foundMaxOriginal.original
      : costArray[0].original;

    const reqCost =
      minCost === maxCost ? minOriginal : `${minOriginal}-${maxOriginal}`;

    result.push({
      title: 'Envío en fecha programada',
      price: reqCost,
      date: '',
      month: '',
      show: true,
      isDateRange: false
    });
  }

  return result;
};

const getHomeDeliverySlots = async ({
  regionCode,
  store,
  state,
  municipal,
  offeringId,
  isFreeShippingApplicable
}) => {
  const commerceHeaders = getCommerceHeaders(regionCode, store);
  const res = await httpService().get(
    apiConfig.getHomeDeliveryShippingUrl(
      regionCode,
      state,
      municipal,
      offeringId
    ),
    {
      headers: commerceHeaders
    },
    'json'
  );
  const homeDeliverySlots = _.get(
    res,
    'data.data.homeDelivery.deliverySlots',
    []
  );
  const homeDeliveryDateRangeSlots = _.get(
    res,
    'data.data.homeDeliveryDateRange.deliverySlots',
    []
  );
  if (
    (!homeDeliverySlots || homeDeliverySlots.length === 0) &&
    (!homeDeliveryDateRangeSlots || homeDeliveryDateRangeSlots.length === 0)
  ) {
    return [];
  }
  const result = processHomeDeliverySlots(
    homeDeliverySlots,
    homeDeliveryDateRangeSlots,
    regionCode,
    isFreeShippingApplicable
  );
  if (result && Array.isArray(result) && result.length > 0) {
    return result;
  }
  return [];
};

const getSelectedPickupInStoreDetails = (selectedStore, regionCode) => {
  const currentLocaleTime = getLocaleTime(regionCode);
  const currentDate = new Date(currentLocaleTime).getDate();
  const result = [];
  const earliestAvailableSlotDate = Number(
    _.get(selectedStore, 'earliestAvailableSlot.slotDate.date', 0)
  );
  const earliestAvailableSlotMonth = _.get(
    selectedStore,
    'earliestAvailableSlot.slotDate.month',
    ''
  ).toLowerCase();
  if (earliestAvailableSlotDate === currentDate) {
    result.push({
      title: 'Retira hoy',
      price: 'Gratis',
      storeId: selectedStore.storeId,
      storeName: selectedStore.storeName,
      show: true,
      isDateRange: false
    });
    const deliverySlots = _.get(selectedStore, 'deliverySlots', []);
    let i;
    for (i = 0; i < deliverySlots.length; i += 1) {
      const slot = deliverySlots[i];
      const deliveryDate = Number(_.get(slot, 'slotDate.date', 0));
      const deliveryMonth = _.get(slot, 'slotDate.month', '').toLowerCase();
      if (deliveryDate !== currentDate) {
        result.push({
          title: `Retira a partir del ${pad(deliveryDate)}/${
            MONTHS[deliveryMonth]
          }`,
          price: 'Gratis',
          storeId: selectedStore.storeId,
          storeName: selectedStore.storeName,
          show: true,
          isDateRange: false
        });
        break;
      }
    }
  } else {
    result.push({
      title: `Retira a partir del ${pad(earliestAvailableSlotDate)}/${
        MONTHS[earliestAvailableSlotMonth]
      }`,
      price: 'Gratis',
      storeId: selectedStore.storeId,
      storeName: selectedStore.storeName,
      show: true,
      isDateRange: false
    });
  }
  return result;
};

const processPickupInStoreSlots = (listOfStores, regionCode) => {
  const currentLocaleTime = getLocaleTime(regionCode);
  const currentDate = new Date(currentLocaleTime).getDate();

  const result = listOfStores.map((store) => {
    const earliestAvailableSlotDate = Number(
      _.get(store, 'earliestAvailableSlot.slotDate.date', 0)
    );
    const earliestAvailableSlotMonth = _.get(
      store,
      'earliestAvailableSlot.slotDate.month',
      ''
    ).toLowerCase();
    const earliestAvailableSlotInfo =
      earliestAvailableSlotDate === currentDate
        ? 'A partir de hoy'
        : `A partir del ${pad(earliestAvailableSlotDate)}/${
            MONTHS[earliestAvailableSlotMonth]
          }`;
    return {
      ...store,
      earliestAvailableSlotInfo
    };
  });
  return result;
};

const getPickupInStoreSlots = async ({
  regionCode,
  store,
  state,
  municipal,
  offeringId,
  lat,
  long
}) => {
  const commerceHeaders = getCommerceHeaders(regionCode, store);
  const res = await httpService().get(
    apiConfig.getPickupFromStoreShippingUrl(
      regionCode,
      state,
      municipal,
      lat,
      long,
      offeringId
    ),
    {
      headers: commerceHeaders
    },
    'json'
  );
  const stores = _.get(res, 'data.data.storePickUp.listOfStores', []);
  if (!stores || stores.length === 0) {
    return [];
  }
  const result = processPickupInStoreSlots(stores, regionCode);
  if (result && Array.isArray(result) && result.length > 0) {
    return result;
  }
  return [];
};

const checkVariantSelection = (sizes, colorVariants, isSizeSelected) => {
  if (sizes && Array.isArray(sizes) && sizes.length > 0) {
    return !isSizeSelected;
  }
  if (
    colorVariants &&
    Array.isArray(colorVariants) &&
    colorVariants.length > 0
  ) {
    return false;
  }
  return false;
};

const getScreen = (
  type,
  sizes,
  colorVariants,
  isSizeSelected,
  pickupInStoreData
) => {
  if (type === 'HomeDelivery') {
    if (checkVariantSelection(sizes, colorVariants, isSizeSelected)) {
      return 'screen1';
    }
    return 'screen2';
  }
  if (type === 'PickupInStore') {
    if (checkVariantSelection(sizes, colorVariants, isSizeSelected)) {
      return 'screen1';
    }
    if (!_.isEmpty(pickupInStoreData)) {
      return 'screen3';
    }
    return 'screen2';
  }
  return 'screen2';
};

const getSelectedData = (data, type, zoneConfig) => {
  const {
    title,
    price,
    date = '',
    month = '',
    storeName = '',
    storeId = '',
    isDateRange = false
  } = data[0];
  const locationName = _.get(zoneConfig, 'name', '').toLowerCase();
  const politicalId = _.get(zoneConfig, 'politicalId', '');

  if (type === 'HomeDelivery') {
    return {
      selectedType: title,
      price,
      date,
      month,
      storeName,
      storeId,
      isValid: true,
      locationName,
      politicalId,
      isDateRange
    };
  }
  if (type === 'PickupInStore') {
    return {
      selectedType: title,
      price,
      date,
      month,
      storeName,
      storeId,
      isValid: true,
      locationName,
      politicalId,
      isDateRange
    };
  }
  return {
    selectedType: '',
    price: '',
    date: '',
    month: '',
    locationName: '',
    storeName: '',
    storeId: '',
    isValid: false,
    politicalId: ''
  };
};

const getHomeDeliveryData = async ({
  comuna,
  zoneConfig,
  isDefaultComuna,
  isCambiar,
  regionCode,
  store,
  offeringId,
  isFreeShippingApplicable,
  setBusy,
  initial,
  setComunaName,
  setIsError,
  setHomeDeliveryData,
  setScreen,
  setSaveError
}) => {
  if (initial) {
    if (isDefaultComuna || isCambiar) {
      return;
    }
    setBusy(true);
    const hdResult = await getHomeDeliverySlots({
      regionCode,
      store,
      state: _.get(zoneConfig, 'parentStateId', ''),
      municipal: _.get(zoneConfig, 'politicalId', ''),
      offeringId,
      isFreeShippingApplicable
    });
    if (hdResult && Array.isArray(hdResult) && hdResult.length > 0) {
      setBusy(false);
      setIsError(false);
      setHomeDeliveryData(hdResult);
      setComunaName(_.get(zoneConfig, 'name', ''));
      setScreen('screen3');
    } else {
      setBusy(false);
      setComunaName(_.get(zoneConfig, 'name', ''));
      setIsError(true);
      setSaveError(true);
    }
  } else if (comuna && !_.isEmpty(comuna)) {
    setBusy(true);
    const hdResult = await getHomeDeliverySlots({
      regionCode,
      store,
      state: _.get(comuna, 'parentStateId', ''),
      municipal: _.get(comuna, 'politicalId', ''),
      offeringId,
      isFreeShippingApplicable
    });
    if (hdResult && Array.isArray(hdResult) && hdResult.length > 0) {
      setBusy(false);
      setIsError(false);
      setHomeDeliveryData(hdResult);
      setComunaName(_.get(comuna, 'name', ''));
    } else {
      setBusy(false);
      setComunaName(_.get(comuna, 'name', ''));
      setIsError(true);
      if (isDefaultComuna) {
        setSaveError(true);
      }
    }
  }
};

const processStoreId = (
  storeId,
  storesData,
  setSelectedStoreId,
  setSelectedStoreDetails,
  setSelectedStoreName,
  regionCode,
  zoneConfig,
  isFreeShippingApplicable,
  setSelectedDeliveryOption
) => {
  const storeDetails = storesData.find((data) => {
    return data.storeId === storeId;
  });
  if (storeDetails && !_.isEmpty(storeDetails)) {
    const mySelectedStoreDetails = getSelectedPickupInStoreDetails(
      storeDetails,
      regionCode
    );
    setSelectedStoreDetails(mySelectedStoreDetails);
    setSelectedStoreId(storeId);
    setSelectedStoreName(_.get(storeDetails, 'storeName', ''));
    const {
      selectedType,
      price,
      date,
      month,
      storeName,
      isValid,
      locationName,
      politicalId,
      isDateRange
    } = getSelectedData(
      mySelectedStoreDetails,
      'PickupInStore',
      zoneConfig,
      isFreeShippingApplicable
    );

    if (isValid) {
      setSelectedDeliveryOption({
        type: selectedType,
        price,
        date,
        month,
        locationName,
        storeName,
        storeId,
        politicalId,
        isError: false,
        isDateRange,
        isFreeShippingApplicable
      });
    }
    return true;
  }
  return false;
};

const getPickupInStoreData = async ({
  comuna,
  setBusy,
  initial,
  isDefaultComuna,
  isCambiar,
  regionCode,
  store,
  zoneConfig,
  offeringId,
  setComunaName,
  setIsError,
  setPickupInStoreData,
  selectedDeliveryOption,
  setSelectedStoreId,
  setSelectedStoreDetails,
  setSelectedStoreName,
  setSaveError,
  isFreeShippingApplicable,
  setSelectedDeliveryOption,
  setScreen
}) => {
  if (initial) {
    if (isDefaultComuna || isCambiar) {
      return;
    }
    setBusy(true);
    const ccData = await getPickupInStoreSlots({
      regionCode,
      store,
      state: _.get(zoneConfig, 'parentStateId', ''),
      municipal: _.get(zoneConfig, 'politicalId', ''),
      lat: _.get(zoneConfig, 'latitude', ''),
      long: _.get(zoneConfig, 'longitude', ''),
      offeringId
    });
    if (ccData && Array.isArray(ccData) && ccData.length > 0) {
      setIsError(false);
      setPickupInStoreData(ccData);
      if (selectedDeliveryOption && !_.isEmpty(selectedDeliveryOption)) {
        const savedStoreId = _.get(selectedDeliveryOption, 'storeId', '');
        if (!savedStoreId) {
          setSelectedStoreId(_.get(ccData, '[0].storeId', null));
          setComunaName(_.get(zoneConfig, 'name', ''));
          setBusy(false);
          return;
        }
        const processRes = processStoreId(
          savedStoreId,
          ccData,
          setSelectedStoreId,
          setSelectedStoreDetails,
          setSelectedStoreName,
          regionCode,
          zoneConfig,
          isFreeShippingApplicable,
          setSelectedDeliveryOption
        );
        if (!processRes) {
          setComunaName(_.get(zoneConfig, 'name', ''));
          setSelectedStoreId(_.get(ccData, '[0].storeId', null));
          setSaveError(true);
          setBusy(false);
          return;
        }
      } else {
        setSelectedStoreId(_.get(ccData, '[0].storeId', null));
      }
      setComunaName(_.get(zoneConfig, 'name', ''));
      setTimeout(() => {
        setScreen('screen3');
        setBusy(false);
      }, 500);
    } else {
      setBusy(false);
      setComunaName(_.get(zoneConfig, 'name', ''));
      setIsError(true);
      setSaveError(true);
    }
    return;
  }

  if (comuna && !_.isEmpty(comuna)) {
    setBusy(true);
    const ccData = await getPickupInStoreSlots({
      regionCode,
      store,
      state: _.get(comuna, 'parentStateId', ''),
      municipal: _.get(comuna, 'politicalId', ''),
      lat: _.get(comuna, 'latitude', ''),
      long: _.get(comuna, 'longitude', ''),
      offeringId
    });
    if (ccData && Array.isArray(ccData) && ccData.length > 0) {
      setBusy(false);
      setIsError(false);
      setPickupInStoreData(ccData);
      setSelectedStoreId(_.get(ccData, '[0].storeId', null));
      setComunaName(_.get(comuna, 'name', ''));
    } else {
      setBusy(false);
      setComunaName(_.get(comuna, 'name', ''));
      setPickupInStoreData([]);
      setIsError(true);
    }
  }
};

export {
  getHomeDeliveryData,
  getHomeDeliverySlots,
  getPickupInStoreSlots,
  getPickupInStoreData,
  getSelectedPickupInStoreDetails,
  getScreen,
  getSelectedData,
  processStoreId
};
