import {
  getUrlForBrand as getBrandUrlPath,
  getUrlForSeller as getSellerUrlPath,
  isStandalone
} from '@digital-retail/store-manager';
import { isWindow } from '../../utils/deviceChecker';
import constants from '../constants';

import stores from '../storeMap';
import CookieStorage from '../../utils/CookieStorage';

const getRegion = (regionCode) => {
  if (regionCode === 'cl' || !regionCode) {
    return '';
  }
  return `.${regionCode}`;
};

const countryMap = {
  cl: 'FACL',
  co: 'FACO',
  pe: 'FAPE',
  ar: 'FAAR'
};

const getHost = (regionCode, store = null) => {
  const region = getRegion(regionCode);
  let host = `https://www.falabella.com${region}`;
  host = store ? host.replace('www.', `${store}.`) : host;

  return host;
};
const getStandAloneFromHost = (host) => {
  return constants.standAlones.find((standAlone) =>
    standAlone.regex.test(host)
  );
};
const getStoreUrl = (host, regionCode) => {
  const standAlone = getStandAloneFromHost(host);
  if (standAlone) {
    const regionSuffix = getRegion(regionCode);
    const subdomain = host.replace('https://', '').split('.')[0];
    const subdomainToChange =
      subdomain === standAlone.domain ? 'www' : subdomain;
    return `https://${subdomainToChange}.falabella.com${regionSuffix}`;
  }
  const storeFilter = [
    'sodimac',
    'tottus',
    'linio',
    'tienda',
    'homecenter',
    'so_com'
  ].filter((store) => host.includes(store));
  return storeFilter.length > 0
    ? host.replace(`-${storeFilter}`, '').replace(`${storeFilter}`, 'www')
    : host;
};

const getDomain = (regionCode) => {
  const region = getRegion(regionCode);
  return `falabella.com${region}`;
};

export default {
  taxonomyURL: ({ base, regionCode, version }) => {
    // default regionCode is cl since if any static content loading fails, appln will be redirected to errorPage and ctx.query will be undefined.
    return `${base}/${version}/taxonomy/${regionCode || 'cl'}`;
  },
  getTypeaheadUrl({ siteId, value, channel, useNewTypeahead }) {
    if (useNewTypeahead) {
      const base = isWindow() ? window.location.origin : getHost(siteId);
      return `${base}/s/browse/v1/typeahead/${siteId}/${encodeURIComponent(
        value
      )}?source=${channel}`;
    }

    return `https://www.falabella.com/s/typeahead/v3/${encodeURIComponent(
      value
    )}?source=${channel}&country=${siteId}`;
  },
  getProductsUrl({ base, siteId, rest }) {
    return `${base}/v1/search/${siteId}?${Object.keys(rest)
      .map((key) => `${key}=${rest[key]}`)
      .join('&')}`;
  },
  getListingsUrl({ base, siteId, rest }) {
    return `${base}/v1/listing/${siteId}?${Object.keys(rest)
      .map((key) => `${key}=${rest[key]}`)
      .join('&')}`;
  },
  getCollectionUrl({ base, siteId, rest }) {
    return `${base}/v1/collection/${siteId}?${Object.keys(rest)
      .map((key) => `${key}=${rest[key]}`)
      .join('&')}`;
  },
  getHeaderUrl: ({ base, regionCode, version = 'v2', site }) => {
    // default regionCode is cl since if any static content loading fails, appln will be redirected to errorPage and ctx.query will be undefined.
    return `${base}/${version}/header/${regionCode || 'cl'}?site=${site}`;
  },
  getSISNavigationMenuURL: ({
    base,
    regionCode,
    version = 'v2',
    store,
    site
  }) => {
    // default regionCode is cl since if any static content loading fails, appln will be redirected to errorPage and ctx.query will be undefined.
    return `${base}/${version}/sisMenu/${regionCode ||
      'cl'}/${store}?site=${site}`;
  },
  getFooterUrl: ({ base, regionCode, version = 'v2', site }) => {
    // default regionCode is cl since if any static content loading fails, appln will be redirected to errorPage and ctx.query will be undefined.
    return `${base}/${version}/footer/${regionCode || 'cl'}?site=${site}`;
  },
  getTransversalBannerUrl: ({ base, regionCode, version = 'v2' }) => {
    // default regionCode is cl since if any static content loading fails, appln will be redirected to errorPage and ctx.query will be undefined.
    return `${base}/${version}/transversal/${regionCode || 'cl'}`;
  },
  getEmailSubcriptionUrl: (regionCode) => {
    const host = getHost(regionCode);
    return `${host}/rest/model/falabella/rest/common/CommonActor/subscribe-to-newsletter`;
  },
  getAnalyticsInfoUrl: (regionCode) => {
    const base = isWindow() ? window.location.origin : getHost(regionCode);
    return `${base}/rest/model/falabella/rest/common/CommonActor/analytics-info`;
  },
  getCommerceAnalyticsInfoUrl: (regionCode) => {
    const host = isWindow() ? window.location.origin : getHost(regionCode);
    return `${host}/s/account/v1/analytics-info`;
  },
  getLocationsUrl: ({ base, siteId }) => {
    return `${base}/v1/zones/${siteId}`;
  },
  getProductDetailsUrl: ({ base, siteId, params }) => {
    return `${base}/v2/product/${siteId}?${Object.keys(params)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
      )
      .join('&')}`;
  },
  getConnectPlansUrl: ({ base, siteId, params }) => {
    return `${base}/v1/plans/${siteId}?${Object.keys(params)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
      )
      .join('&')}`;
  },
  getLoggingUrl: () => {
    return `/log`;
  },
  getHomeUrl: (regionCode) => {
    const host = getHost(regionCode);
    return `${host}/falabella-${regionCode}`;
  },
  getImagesURL: (regionCode) => {
    let fa = 'Falabella';
    switch (regionCode) {
      case 'co': {
        fa = 'FalabellaCO';
        break;
      }
      case 'ar': {
        fa = 'FalabellaAR';
        break;
      }
      case 'pe': {
        fa = 'FalabellaPE';
        break;
      }
      default: {
        fa = 'Falabella';
      }
    }
    return `https://falabella.scene7.com/is/image/${fa}`;
  },
  getBackPDP: (regionCode, productId) => {
    return `/falabella-${regionCode}/product/${productId}`;
  },
  imagesURL: 'https://falabella.scene7.com/is/image/Falabella/',
  imagesBaseURL: 'https://falabella.scene7.com/is/image',
  getComboItemsUrl: ({
    regionCode,
    site,
    bundleIds,
    zones = '',
    politicalId = '',
    priceGroupId = ''
  }) => {
    const base = isWindow() ? window.location.origin : getHost(regionCode);
    const siteParam = site ? `&site=${site}` : '';
    const hostUrl = getStoreUrl(base, regionCode);
    const zonesString = zones.join('","');
    return `${hostUrl}/s/browse/v2/fetchItemDetails/${regionCode}?politicalId=${politicalId}&zones=${zonesString}&priceGroupId=${priceGroupId}&appName=novios${siteParam}&ids=${bundleIds.join(
      ','
    )}`;
  },
  getWidgets: ({
    productId,
    zones,
    regionCode,
    channel,
    site,
    widgetsUUID,
    userId,
    variantId,
    slots,
    categoryId,
    webCategoryId,
    brandName,
    sellerId,
    contextId,
    politicalId,
    priceGroupId
  }) => {
    const base = isWindow() ? window.location.origin : getHost(regionCode);
    const siteParam = site ? `&site=${site}` : '';
    const hostUrl = getStoreUrl(base, regionCode);
    const slotsParam = slots ? `&slots=${slots}` : '';
    const widgetsUUIDParam = widgetsUUID ? `&widgetsUUID=${widgetsUUID}` : '';
    const politicalIdParam = politicalId ? `&politicalId=${politicalId}` : '';
    return `${hostUrl}/s/browse/v2/recommended-products/${regionCode}?channel=${channel}&productId=${productId}&variantId=${variantId}&userId=${userId}&zones=${zones}&categoryId=${categoryId}&webCategoryId=${webCategoryId}${widgetsUUIDParam}${siteParam}${slotsParam}&brandName=${brandName}&sellerId=${sellerId}${politicalIdParam}${
      contextId ? `&contextId=${contextId}` : ``
    }&pageType=PDP&priceGroupId=${priceGroupId}`;
  },
  getSponsoredProducts: ({
    productId,
    zones,
    regionCode,
    deviceType,
    site,
    politicalId,
    brandName,
    gender
  }) => {
    const base = isWindow() ? window.location.origin : getHost(regionCode);
    const hostUrl = getStoreUrl(base, regionCode);
    const politicalIdParam = politicalId ? `&politicalId=${politicalId}` : '';
    const brandNameParam = brandName ? `&brandName=${brandName}` : '';
    const genderParam = gender ? `&gender=${gender}` : '';

    const isDebugAdsEnabled = isWindow()
      ? CookieStorage.getCookie('isDebugAdsEnabled') === 'true'
      : false;

    const debug = isDebugAdsEnabled ? `&debug=true` : '';

    return `${hostUrl}/s/browse/v1/sponsored-products/${regionCode}?&channel=${deviceType}&productId=${productId}&zones=${zones}&deviceType=${deviceType}${politicalIdParam}${brandNameParam}${genderParam}${debug}${
      site ? `&site=${site}` : ''
    }`;
  },
  getUserSessionInfo: (regionCode, url) => {
    const base = isWindow() ? window.location.origin : getHost(regionCode);
    return `${base}${url}`;
  },
  getCanonicalUrl: ({ regionCode, searchTerm, store }) => {
    const host = getHost(regionCode, store);
    const BU = store ? stores[store.toLowerCase()] : 'falabella';
    return `${host}/${BU}-${regionCode}/search/?Ntt=${searchTerm}`;
  },
  getCMRCalculationUrl: (regionCode) => {
    // const base = getHost(regionCode);
    // TODO: NEED TO REMOVE ONCE FIND OTHER ALTERNATIVE SOLUTION FOR CORS ISSUE
    const isClientSide =
      isWindow() || (window && window.location && window.location.origin);
    const base = isClientSide ? window.location.origin : getHost(regionCode);
    const hostUrl = getStoreUrl(base, regionCode);
    return `${hostUrl}/rest/model/falabella/rest/browse/BrowseActor/init-monthly-installment`;
  },
  getProductShippingForCalendar: ({
    regionCode,
    skuId,
    skuQuantity,
    regionId,
    comunaId,
    pageIndex
  }) => {
    const query = `{"skuId":${`${skuId}`},"skuQuantity":${skuQuantity},"regionId":${regionId},"comunaId":${comunaId}, "pageIndex":${pageIndex}}`;
    // TODO: NEED TO REMOVE ONCE FIND OTHER ALTERNATIVE SOLUTION FOR CORS ISSUE
    const base = isWindow() ? window.location.origin : getHost(regionCode);
    return `${base}/rest/model/falabella/rest/browse/BrowseActor/get-product-shipping?${escape(
      query
    )}`;
  },
  getProductShippingForStores: ({
    regionCode,
    skuId,
    skuQuantity,
    regionId,
    comunaId,
    tabSelected
  }) => {
    const query = `{"skuId":${`${skuId}`},"skuQuantity":${skuQuantity},"regionId":${regionId},"comunaId":${comunaId}, "tabSelected":${tabSelected}}`;
    // TODO: NEED TO REMOVE ONCE FIND OTHER ALTERNATIVE SOLUTION FOR CORS ISSUE
    const base = isWindow() ? window.location.origin : getHost(regionCode);
    return `${base}/rest/model/falabella/rest/browse/BrowseActor/get-product-shipping?${escape(
      query
    )}`;
  },
  getPromotionProductsURL: ({ regionCode }) => {
    // TODO: NEED TO REMOVE ONCE FIND OTHER ALTERNATIVE SOLUTION FOR CORS ISSUE
    const base = isWindow() ? window.location.origin : getHost(regionCode);
    return `${base}/rest/model/falabella/rest/browse/BrowseActor/init-promoted-products`;
  },
  fetchItemDetails: (regionCode, query) => {
    // const host = getHost(regionCode);
    // TODO: NEED TO REMOVE ONCE FIND OTHER ALTERNATIVE SOLUTION FOR CORS ISSUE
    const host = isWindow() ? window.location.origin : getHost(regionCode);
    return `${host}/s/browse/v1/fetchItemDetails/${regionCode}?${query}`;
  },
  addToCartCommerceUrl: (query, regionCode) => {
    const host = isWindow() ? window.location.origin : getHost(regionCode);
    const standalone = getStandAloneFromHost(host);
    const hostUrl = standalone ? host : getStoreUrl(host, regionCode);
    return `${hostUrl}/s/checkout/v1/carts${query}`;
  },
  addToListCommerceUrl: (regionCode) => {
    const host = isWindow() ? window.location.origin : getHost(regionCode);
    const hostUrl = getStoreUrl(host, regionCode);
    return `${hostUrl}/s/checkout/v1/lists`;
  },
  getAllListsCommerceUrl: (listType, regionCode, politicalId) => {
    const host = isWindow() ? window.location.origin : getHost(regionCode);
    const hostUrl = getStoreUrl(host, regionCode);
    const url = new URL(
      `${hostUrl}/s/checkout/v2/lists?listType=${listType}&politicalAreaId=${politicalId}`
    );
    url.searchParams.set('includeLines', 'true');
    return url;
  },
  dleteFromListCommerceUrl: (listId, listLineItemId, regionCode) => {
    const host = isWindow() ? window.location.origin : getHost(regionCode);
    const hostUrl = getStoreUrl(host, regionCode);
    return `${hostUrl}/s/checkout/v1/lists/${listId}/list-lines/${listLineItemId}`;
  },
  patchCartlineCommerceUrl: (cartId, regionCode) => {
    const host = isWindow() ? window.location.origin : getHost(regionCode);
    const standalone = getStandAloneFromHost(host);
    const hostUrl = standalone ? host : getStoreUrl(host, regionCode);
    return `${hostUrl}/s/checkout/v1/carts/${cartId}/cart-lines`;
  },
  removeCartlineCommerceUrl: (cartId, regionCode) => {
    const host = isWindow() ? window.location.origin : getHost(regionCode);
    return `${host}/s/checkout/v1/carts/${cartId}/remove-items`;
  },
  deleteCartlineCommerceUrl: (cartId, cartLineItemId, regionCode) => {
    const host = isWindow() ? window.location.origin : getHost(regionCode);
    const standalone = getStandAloneFromHost(host);
    const hostUrl = standalone ? host : getStoreUrl(host, regionCode);
    return `${hostUrl}/s/checkout/v1/carts/${cartId}/cart-lines/${cartLineItemId}`;
  },
  getAddToCartUrl: (regionCode) => {
    // const host = getHost(regionCode);
    // TODO: NEED TO REMOVE ONCE FIND OTHER ALTERNATIVE SOLUTION FOR CORS ISSUE
    const host = isWindow() ? window.location.origin : getHost(regionCode);
    return `${host}/rest/model/atg/commerce/order/purchase/CartModifierActor/addItemToBasket`;
  },
  getFetchSessionInfoUrl: (regionCode) => {
    // const host = getHost(regionCode);
    const host = isWindow() ? window.location.origin : getHost(regionCode);
    return `${host}/rest/model/atg/userprofiling/ProfileActor/fetchSessionInfo`;
  },
  getSetAssemblyServiceUrl: (regionCode) => {
    // const host = getHost(regionCode);
    // TODO: NEED TO REMOVE ONCE FIND OTHER ALTERNATIVE SOLUTION FOR CORS ISSUE
    const host = isWindow() ? window.location.origin : getHost(regionCode);
    return `${host}/rest/model/atg/commerce/order/purchase/CartModifierActor/set-assembly-service`;
  },
  getViewPlanUrl: ({ productId, productName, color, regionCode }) => {
    const host = getHost(regionCode);
    return `${host}/falabella-${regionCode}/plans/${productId}/${productName}/:${color}`;
  },
  getBrandURL: ({ brandName, regionCode, env, store = '' }) => {
    const encodedBrand = encodeURIComponent(brandName);
    const brandUrlPath = getBrandUrlPath({
      store: isStandalone({ store }) ? store : '',
      regionCode,
      environment: env
    });
    return `${brandUrlPath}/${encodedBrand}`;
  },
  getBrandApiURL({ base, siteId, params }) {
    return `${base}/v1/brand/${siteId}?${Object.keys(params)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
      )
      .join('&')}`;
  },
  getSellerURL: ({ sellerName, regionCode, env, store = '' }) => {
    const encodedSeller = encodeURIComponent(sellerName);
    const sellerUrlPath = getSellerUrlPath({
      store: isStandalone({ store }) ? store : '',
      environment: env,
      regionCode
    });
    return `${sellerUrlPath}/${encodedSeller}`;
  },
  getSellerApiURL({ base, siteId, params }) {
    return `${base}/v1/seller/${siteId}?${Object.keys(params)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
      )
      .join('&')}`;
  },
  applyCmrOnline: (sessionConfirmationNumber, regionCode) => {
    const query = `{"sessionConfirmationNumber":"${sessionConfirmationNumber}"}`;

    const base = isWindow() ? window.location.origin : getHost(regionCode);

    return `${base}/rest/model/atg/userprofiling/ProfileActor/apply-cmr-online?${escape(
      query
    )}`;
  },
  retrieveCMRToken: ({ regionCode }) => {
    const base = isWindow() ? window.location.origin : getHost(regionCode);
    return `${base}/rest/model/atg/userprofiling/ProfileActor/retrieve-cmrcard-token`;
  },
  getSizeCharturl: (url, regionCode) => {
    const host = getHost(regionCode);
    return url[0] === '/' ? `${host}${url}` : url;
  },
  formatRelativeUrl: (url, regionCode) => {
    const host = getHost(regionCode);
    return `${host}${url}`;
  },
  getLogoutUrl: (regionCode) => {
    const host = isWindow() ? window.location.origin : getHost(regionCode);
    return `${host}/rest/model/atg/userprofiling/ProfileActor/logout`;
  },
  getSurveyPageLink: (regionCode, clientId) => {
    const query = `cid=hDU2VlkVuP0ABoUXD88Djw==&sid=feedback-n-${getDomain(
      regionCode
    )}-es&cpp[site]=${getDomain(regionCode)}&cpp[clienteID]=${clientId}']`;
    return `https://survey.foreseeresults.com/survey/display?${query}`;
  },
  saveCardInfotoProfile: (regionCode) => {
    // const host = getHost(regionCode);
    // TODO: NEED TO REMOVE ONCE FIND OTHER ALTERNATIVE SOLUTION FOR CORS ISSUE
    const host = isWindow() ? window.location.origin : getHost(regionCode);
    return `${host}/rest/model/atg/userprofiling/ProfileActor/save-card-info-to-profile`;
  },
  savePromotionInfotoProfile: (regionCode) => {
    // const host = getHost(regionCode);
    // TODO: NEED TO REMOVE ONCE FIND OTHER ALTERNATIVE SOLUTION FOR CORS ISSUE
    const host = isWindow() ? window.location.origin : getHost(regionCode);
    return `${host}/rest/model/atg/userprofiling/ProfileActor/save-promotion-info-to-profile`;
  },
  saveInstallmentInfotoProfile: (regionCode) => {
    // const host = getHost(regionCode);
    // TODO: NEED TO REMOVE ONCE FIND OTHER ALTERNATIVE SOLUTION FOR CORS ISSUE
    const host = isWindow() ? window.location.origin : getHost(regionCode);
    return `${host}/rest/model/atg/userprofiling/ProfileActor/save-installment-info-to-profile`;
  },
  getProductAvailabilityInStore: (sku, vendorData, regionCode) => {
    // const host = getHost(regionCode);
    // TODO: NEED TO REMOVE ONCE FIND OTHER ALTERNATIVE SOLUTION FOR CORS ISSUE
    const host = isWindow() ? window.location.origin : getHost(regionCode);
    return `${host}/rest/model/falabella/rest/browse/BrowseActor/storeItemInfo?{"skuId":"${sku}","storeLocalId":"${
      vendorData.storeId
    }","vendorId":"${vendorData.vendorId}","vendorCode":"${
      vendorData.vendorCode
    }"}`;
  },
  requestSkuStocksFromStore: (sku, vendorData, regionCode) => {
    // const host = getHost(regionCode);
    // TODO: NEED TO REMOVE ONCE FIND OTHER ALTERNATIVE SOLUTION FOR CORS ISSUE
    const host = isWindow() ? window.location.origin : getHost(regionCode);
    return `${host}/rest/model/falabella/rest/browse/BrowseActor/storeSkuRequest?{"skuId":"${sku}","storeLocalId":"${
      vendorData.storeId
    }","vendorId":"${vendorData.vendorId}","vendorCode":"${
      vendorData.vendorCode
    }"}`;
  },
  goToPage: (url, regionCode) => {
    const host = isWindow() ? window.location.origin : getHost(regionCode);
    return `${host}/falabella-${regionCode}/${url}`;
  },
  getEnvironmentSpecificUrl: (url, env) => {
    return url.replace('www', env);
  },
  getSizeInfoURL: (
    isNewSizeGroupService,
    regionCode,
    productId,
    sizes,
    backendCategoryId,
    webCategoryId,
    brandId
  ) => {
    const host = isWindow() ? window.location.origin : getHost(regionCode);
    const country = countryMap[regionCode];

    const sizeStr = encodeURIComponent(sizes.join(','));
    const path = isNewSizeGroupService ? '/browse' : '';
    return `${host}/s${path}/size/size-info?id=${productId}&country=${country}&sizes=${sizeStr}&backendCategory=${backendCategoryId}&webCategory=${webCategoryId}&brandId=${encodeURIComponent(
      brandId
    )}`;
  },

  getRegions: (regionCode) => {
    const host = isWindow() ? window.location.origin : getHost(regionCode);
    return `${host}/s/checkout/v1/geo/regions`;
  },
  getRegionsFromGeoApi: (regionCode) => {
    const host = isWindow() ? window.location.origin : getHost(regionCode);
    const hostUrl = getStoreUrl(host, regionCode);

    return `${hostUrl}/s/geo/v2/states/${regionCode}`;
  },
  getDistrictFromGeoApi: (regionCode, politicalId) => {
    const host = isWindow() ? window.location.origin : getHost(regionCode);
    const hostUrl = getStoreUrl(host, regionCode);

    return `${hostUrl}/s/geo/v2/districts/${regionCode}?politicalId=${politicalId}`;
  },
  getCommunasFromGeoApi: (regionCode, politicalId, countyPoliticalId = '') => {
    const host = isWindow() ? window.location.origin : getHost(regionCode);

    const hostUrl = getStoreUrl(host, regionCode);

    return regionCode !== 'cl'
      ? `${hostUrl}/s/geo/v2/districts/${regionCode}?statePoliticalId=${politicalId}&countyPoliticalId=${countyPoliticalId}`
      : `${hostUrl}/s/geo/v2/districts/cl?statePoliticalId=${politicalId}`;
  },
  getProvincesFromGeoApi: (regionCode, politicalId) => {
    const host = isWindow() ? window.location.origin : getHost(regionCode);

    const hostUrl = getStoreUrl(host, regionCode);

    return `${hostUrl}/s/geo/v2/counties/${regionCode}?statePoliticalId=${politicalId}`;
  },
  getCommunaCuidad: (regionCode, query) => {
    const host = isWindow() ? window.location.origin : getHost(regionCode);

    return `${host}/s/checkout/v1/geo/get-selected-locations${query}`;
  },
  getPickupFromStoreShippingUrl: (
    regionCode,
    state,
    municipal,
    lat,
    long,
    offeringId
  ) => {
    const host = isWindow() ? window.location.origin : getHost(regionCode);

    const hostUrl = getStoreUrl(host, regionCode);
    return `${hostUrl}/s/checkout/v1/shipments/delivery-estimates/products?offeringId=${offeringId}&state=${state}&municipal=${municipal}&deliveryMethod=storePickUp&lat=${lat}&long=${long}`;
  },
  getHomeDeliveryShippingUrl: (regionCode, state, municipal, offeringId) => {
    const host = isWindow() ? window.location.origin : getHost(regionCode);
    const hostUrl = getStoreUrl(host, regionCode);
    return `${hostUrl}/s/checkout/v1/shipments/delivery-estimates/products?offeringId=${offeringId}&state=${state}&municipal=${municipal}&deliveryMethod=homeDelivery&numberOfDays=7`;
  },
  getProductLiteDetailsUrl({ regionCode, rest }) {
    const host = isWindow() ? window.location.origin : getHost(regionCode);
    const hostUrl = getStoreUrl(host, regionCode);
    return `${hostUrl}/s/browse/v1/product/${regionCode}/lite?${Object.keys(
      rest
    )
      .map((key) => `${key}=${rest[key]}`)
      .join('&')}`;
  },
  getGeoUrl: ({ base, siteId }) => {
    return `${base}/v2/states/${siteId}`;
  },
  mabayaVisitUrl: ({ storeHash, sku, referrerSignal, cookie }) => {
    return `https://us.evt.mb-srv.com/v2/server2server/visit?storeHash=${storeHash}&sku=${sku}&referrerSignal=${referrerSignal}${
      cookie ? `&cookie=${cookie}` : ''
    }&referrerType=other`;
  },
  mabayaClickUrl: ({ storeHash, platform, adInfo, mPos, cookie }) => {
    return `https://us.widget.mb-srv.com/v2/server2server/click?storeHash=${storeHash}&cookie=${cookie}&platform=${platform}&mabaya_ad_info=${adInfo}&mPos=${mPos}`;
  },
  mabayaImpressionUrl: ({ storeHash, platform, adInfo, mPos }) => {
    return `https://us.widget.mb-srv.com/v2/server2server/imp?storeHash=${storeHash}&platform=${platform}&mabaya_ad_info=${adInfo}&mPos=${mPos}`;
  },
  getDistrictInfo: ({ base, siteId, politicalId, latLong }) => {
    let url = `${base}/v2/districts/${siteId}?politicalId=${politicalId}`;
    if (latLong?.latitude && latLong?.longitude) {
      url += `&latitude=${latLong?.latitude}&longitude=${latLong?.longitude}`;
    }
    return url;
  },
  getStoreAvailabilityUrl: ({
    offeringId,
    sellerId,
    latitude,
    longitude,
    regionCode
  }) => {
    const host = isWindow() ? window.location.origin : getHost(regionCode);
    const hostUrl = getStoreUrl(host, regionCode);
    return `${hostUrl}/s/geo/v1/stores/${regionCode}?offeringId=${offeringId}&sellerId=${sellerId}&latitude=${latitude}&longitude=${longitude}`;
  },
  getGoogleMapLink: ({ lat, lng }) => {
    return `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`;
  },
  getCheckoutExpressUrl: (regionCode) => {
    const host = isWindow() ? window.location.origin : getHost(regionCode);
    return `${host}/falabella-${regionCode}/checkout/express?flow=BUY_NOW`;
  },
  getLowestPlanUrl: ({ regionCode, rest }) => {
    const host = getHost(regionCode);
    return `${host}/s/browse/v1/search/${regionCode}?${Object.keys(rest)
      .map((key) => `${key}=${rest[key]}`)
      .join('&')}`;
  },
  sponsoredAdsUrl: (baseUrl) => `${baseUrl}/v1/vas/ads`,
  sponsoredClickUrl: (baseUrl) => `${baseUrl}/v1/vas/click`,
  sponsoredImpressionUrl: (baseUrl) => `${baseUrl}/v1/vas/impression`,
  sponsoredVisitUrl: (baseUrl) => `${baseUrl}/v1/vas/visit`,
  getStoreUrl
};
