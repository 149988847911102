import _ from '../../../utils/LodashImports';
import constants from '../../../config/constants';

const getWarrantyLabel = ({ textLabel = '', sellerName, warrantyPrefix }) => {
  if (constants.SODIMAC_REGEX.test(sellerName)) {
    const regex = /\b(\d+)A\b/;
    const match = textLabel.match(regex);
    if (match) {
      const years = match[1];
      return `${warrantyPrefix} ${years} Año${years === '1' ? '' : 's'}`;
    }
    return textLabel;
  }
  return `${warrantyPrefix} ${textLabel}`;
};

const getWarrantyBenefits = ({
  siteConfig = {},
  regionCode = 'cl',
  gCategoryId = ''
}) => {
  const fallbackGenericWarrantyBenefits = {
    cl: [
      'Producto de reemplazo tras diágnostico de falla',
      'Cobertura en caso de variacion de voltaje',
      'Reembolso automatico para productos menores a $80.000, luego de revision',
      'Trabajo realizado por expertos',
      'Uso de repuestos originales',
      'Evaluación gratuita',
      'Garantía transferible',
      'Cobertura Nacional'
    ],
    pe: [
      'Uso de repuestos originales',
      'Mano de Obra Certificada',
      'Transportes y fletes en caso de requerimiento',
      'Extension de la garantia de fabrica de 1 a 4 años',
      'Daño accidental primeros 30 dias (una vez entregado el producto)',
      'Cobertura en caso de variacion de voltaje',
      'Cobertura Nacional',
      'Visiticas tecnicas y diagnosticos gratis',
      'Garantía de reparacion-uso'
    ],
    co: [
      'Uso de repuestos originales',
      'Mano de Obra Certificada',
      'Transportes y fletes en caso de requerimiento',
      'Extensión de la garantía de fábrica de 1 a 4 años',
      'Daño accidental primeros 30 días (una vez entregado el producto)',
      'Cobertura en caso de variación de voltaje',
      'Cobertura Nacional',
      'Visitas técnicas y diagnósticos gratis',
      'Garantía de reparación-uso'
    ]
  };
  const fallbackGCatWarrantyBenefits = {
    cl: {
      G1907: [
        'Reembolso para compras inferiores a $100.000, luego de revision por parte del servicio tecnico.'
      ]
    },
    co: {
      G1908: [
        'Televisores: Indemnizacion inmediata, previo validación del tecnico'
      ],
      G1403: [
        'Productos cuyo Monto sea entre $60.000 -$ 350.000 se indeminza el valor del producto. Solo pequeños electrodomesticos.'
      ]
    },
    pe: {}
  };

  const warrantyBenefits = _.get(
    siteConfig,
    `configurations.genericWarrantyBenefits.${regionCode}`,
    fallbackGenericWarrantyBenefits[regionCode]
  );
  const gCatWarrantyBenefitsList = _.get(
    siteConfig,
    `configurations.gCatWarrantyBenefits.${regionCode}`,
    fallbackGCatWarrantyBenefits[regionCode]
  );

  if (gCatWarrantyBenefitsList) {
    const gCatMatch = Object.keys(gCatWarrantyBenefitsList).find((g) =>
      gCategoryId.startsWith(g)
    );
    if (gCatMatch) {
      warrantyBenefits.push(gCatWarrantyBenefitsList[gCatMatch]);
    }
  }
  return warrantyBenefits.flat();
};
const isWarrantyRecommended = (warrantyText = '') => {
  return warrantyText.toLowerCase().includes('2 años');
};
const getSelectedIndex = ({ options = [], warrantyOptionCurrent }) => {
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < options.length; i++) {
    if (
      options[i].offeringId &&
      options[i].offeringId === warrantyOptionCurrent?.offeringId
    )
      return i;
  }
  return warrantyOptionCurrent ? options.length - 1 : null;
};

export {
  getWarrantyBenefits,
  getSelectedIndex,
  isWarrantyRecommended,
  getWarrantyLabel
};
