import React, { Fragment, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Prices from '../Prices/Prices';
import { withLabels } from '../../utils/LabelsContext';
import constants from '../../config/constants';
import { cmrApplicableRegion } from '../../config/tenant/tenant.config';
import { ProductPricesStyles } from './ProductPrices.style';
import { withApplicationContext } from '../../utils/ApplicationContext';
import { withProduct } from '../../utils/ProductContext';
import _ from '../../utils/LodashImports';
import { svgToDataUri } from '../../utils/styles/svgMixins';
import { formatters } from '../../utils/PriceHelpers';
import { icons } from '../../config/styles';
import Modal from '../ui/Modal/Modal';
import CmrOptionLabel from './CmrOptionLabel';
import CmrOptionsList from './CmrOptionsList';
import { getMeasurements, getVariant } from '../../utils/variant';
import { getCmrPoints } from '../../utils/CmrPointsUtils';

const ProductPrices = ({
  prices,
  productId,
  labels,
  regionCode,
  isPumAvailable,
  pumQuantity,
  pumUnit,
  cmrPoints,
  primeShipping,
  isVendorLogin,
  currentTab,
  storeSkuData,
  isConnectProduct,
  operators,
  selectedOperatorId,
  isMobile,
  discountBadge,
  appCtx,
  product,
  darSectionActive
}) => {
  const { deviceType, isRebrandingEnabled } = appCtx;
  const [pricesModalVisible, setPricesModalVisible] = useState(false);
  const handleClick = () => {
    setPricesModalVisible(!pricesModalVisible);
  };
  const isCmrValid = !!(cmrApplicableRegion[regionCode] && cmrPoints);
  const getSizeModal = () => {
    if (deviceType === 'desktop') {
      return { width: 480, height: 387, disableOutsideClick: false };
    }
    if (deviceType === 'mobile') {
      return { height: 364, width: 375, disableOutsideClick: false };
    }
    if (deviceType === 'tablet') {
      return { width: 768, disableOutsideClick: false };
    }
    return { disableOutsideClick: true };
  };
  const getLowerPrice = () => {
    const pricesValueObject = prices.map((priceObj) =>
      parseInt(priceObj.price[0].replace(/\./g, ''), 10)
    );
    pricesValueObject.sort((a, b) => a - b);

    return pricesValueObject[0];
  };

  const getStorePrices = (storePrices, internetPrices) => {
    if (!storePrices.price) return internetPrices;
    const { price, normalPrice } = storePrices;
    return [
      {
        icons: '',
        label: '',
        price: [`${normalPrice}`],
        symbol: '',
        type: 'normalPrice'
      },
      {
        icons: '',
        label: '',
        price: [`${price}`],
        symbol: '',
        type: 'storePrice'
      }
    ];
  };
  const newPrices =
    isVendorLogin && currentTab === 'Tienda'
      ? getStorePrices(storeSkuData, prices)
      : prices;
  const isNewPriceApplicable = _.get(
    appCtx,
    'siteConfig.toggles.isNewPriceApplicable',
    false
  );
  const isNewCMRDesignEnabledPDP = _.get(
    appCtx,
    'siteConfig.toggles.isNewCMRDesignEnabledPDP',
    false
  );

  const cmrData = useMemo(() => getCmrPoints(labels, regionCode), [labels]);
  const getTopMargin = () => {
    const priceCount = newPrices.length;
    const hasCrossedPrice = newPrices.some((p) => p.crossed);
    let topMargin = '11';
    if (priceCount === 3) {
      topMargin = '4';
    } else if (priceCount === 2 && hasCrossedPrice) {
      topMargin = '18';
    } else if (priceCount === 2) {
      topMargin = '8';
    } else if (priceCount === 1) {
      topMargin = '9';
    }
    return topMargin;
  };
  const topMargin = getTopMargin();
  const cmrPointsClasses = isNewPriceApplicable ? ` mt-${topMargin}px` : '';
  const validCmrRegion = cmrData;

  const cmrOption = validCmrRegion
    ? cmrData.find((option) => option.title.includes('CMR'))
    : null;

  const divideFactor = cmrOption ? cmrOption.divideFactor : 200;
  const crmPoints = formatters.DOT_SEPERATOR(
    Math.trunc(getLowerPrice() / divideFactor)
  );
  const measurement = getMeasurements({ product });

  const { isOutOfStock, variants = [] } = product;
  const currentVariant = getVariant(variants, product.currentVariant);
  const currentOfferingId = _.get(currentVariant, 'offerings[0].offeringId');
  const promotions = _.get(currentVariant, 'promotions', []);
  const [appPromotion, setAppPromotion] = useState(false);
  const androidPlayStore = _.get(
    appCtx,
    'siteConfig.textDictionary.ANDROID_PLAYSTORE_LINK',
    'https://play.google.com/store/apps/details?id=com.falabella.falabellaApp'
  );
  const iOSAppStore = _.get(
    appCtx,
    'siteConfig.textDictionary.IOS_APPSTORE_LINK',
    'https://apps.apple.com/us/app/falabella-com-tienda-online/id947145705'
  );
  const redirectTimeOut = _.get(
    appCtx,
    'siteConfig.textDictionary.APP_REDIRECTION_TIMEOUT',
    1000
  );
  const redirectHandler = () => {
    /** !('Chrome') && !'CriOS' && 'Safari'
     * 1. Chrome Safari should be false
     *    false && true && true  = false
     *  2. crios safari should be false
     *     true && false && true  = false
     * 3. safari should be true
     *      true && true && true = true
     */
    if (
      !navigator.userAgent.includes('Chrome') &&
      !navigator.userAgent.includes('CriOS') &&
      navigator.userAgent.includes('Safari')
    )
      return;
    const { href } = window.location;
    const appUrl = href.replace('https://', 'com.falabella://');
    const timer = setTimeout(() => {
      const isIOS = /iPhone/.test(navigator.userAgent);
      window.location.href = isIOS ? iOSAppStore : androidPlayStore;
    }, redirectTimeOut);
    window.location.href = appUrl;
    window.onblur = () => {
      clearTimeout(timer);
    };
  };

  useEffect(() => {
    setAppPromotion(false);
    if (promotions) {
      setAppPromotion(
        promotions.find((promotion) => promotion.campaignName === 'PRICEAPP')
      );
    }
  }, [promotions]);
  return (
    <Fragment>
      <Prices
        priceList={newPrices}
        productId={productId}
        variantId={product.currentVariant}
        offeringId={currentOfferingId}
        page={constants.PAGES.PDP}
        priceCount={prices.length}
        regionCode={regionCode}
        isPumAvailable={isPumAvailable}
        pumQuantity={pumQuantity}
        pumUnit={pumUnit}
        variant={isMobile ? 'PDP_MAIN_MOBILE' : 'PDP_MAIN'}
        labels={labels}
        operators={operators}
        selectedOperatorId={selectedOperatorId}
        isConnectProduct={isConnectProduct}
        discountBadge={discountBadge}
        isOutOfStock={isOutOfStock}
        measurement={measurement}
        darSectionActive={darSectionActive}
      />
      {appPromotion && isMobile && (
        <div className="priceAppPromotion">
          <i className="phoneIcon" />
          <button
            className="priceAppPromotionLabel"
            type="submit"
            id="redirectToApp"
            onClick={redirectHandler}
          >
            {appPromotion.description}
          </button>
        </div>
      )}
      {primeShipping && !isOutOfStock && (
        <i id="boomIcon" className="icon boomIcon fa--boomIcon">
          <span className="sr-only">{labels.POD_BOOM_SHIPPING_LABEL}</span>
        </i>
      )}

      {isCmrValid &&
        !isOutOfStock &&
        (isNewCMRDesignEnabledPDP ? (
          <div id="cmr-points" className="new-cmr-points">
            <CmrOptionLabel
              labels={labels}
              cmrPuntosIcons={icons.cmrPuntosIcons}
              cmrPuntosTextIcon={icons.cmrPuntosTextIcon}
              cmrPoints={crmPoints}
            />
            <div
              className="more-link-container"
              onClick={handleClick}
              role="presentation"
              id="testId-link-container"
            >
              <button
                className="more-options"
                id="testId-more-options"
                type="button"
              >
                {labels.MORE_OPTIONS || 'Ver opciones'}
              </button>
              <img
                src={svgToDataUri(icons.cmrMoreIcon)}
                className="cmr-more-icon"
                alt="right"
              />
            </div>
          </div>
        ) : (
          <div
            id="cmr-points"
            className={`cmr-points fa--cmr-points${cmrPointsClasses}`}
          >
            {`${labels.ACCUMULATE} ${cmrPoints} ${labels.CMR_POINTS}`}
          </div>
        ))}
      {isNewCMRDesignEnabledPDP && pricesModalVisible && (
        <Modal
          content={(actions) => {
            return (
              <CmrOptionsList
                actions={actions}
                options={cmrData}
                labels={labels}
                lowerPrice={getLowerPrice()}
                closeModal={handleClick}
              />
            );
          }}
          options={getSizeModal()}
          withPadding={false}
          deviceType={deviceType}
          visible={pricesModalVisible}
          toggleState={{
            visible: pricesModalVisible,
            setVisible: (bool) => setPricesModalVisible(bool)
          }}
          isRebrandingEnabled={isRebrandingEnabled}
          fullWidth={deviceType !== 'desktop'}
          showCloseButton={false}
          className="bottom"
          customClassBody="auto"
        />
      )}

      <style jsx>{ProductPricesStyles}</style>
    </Fragment>
  );
};

ProductPrices.defaultProps = {
  productId: '',
  isPumAvailable: false,
  pumQuantity: 1,
  primeShipping: false,
  cmrPoints: undefined,
  isVendorLogin: false,
  currentTab: '',
  pumUnit: '',
  storeSkuData: {},
  operators: [],
  selectedOperatorId: undefined,
  isConnectProduct: false,
  isMobile: false,
  discountBadge: undefined,
  darSectionActive: false
};

ProductPrices.propTypes = {
  prices: PropTypes.arrayOf(PropTypes.object).isRequired,
  labels: PropTypes.object.isRequired,
  regionCode: PropTypes.string.isRequired,
  cmrPoints: PropTypes.number,
  primeShipping: PropTypes.bool,
  isPumAvailable: PropTypes.bool,
  pumQuantity: PropTypes.number,
  productId: PropTypes.string,
  currentTab: PropTypes.string,
  isVendorLogin: PropTypes.bool,
  storeSkuData: PropTypes.object,
  pumUnit: PropTypes.string,
  isConnectProduct: PropTypes.bool,
  operators: PropTypes.arrayOf(PropTypes.object),
  selectedOperatorId: PropTypes.string,
  isMobile: PropTypes.bool,
  discountBadge: PropTypes.object,
  appCtx: PropTypes.object.isRequired,
  product: PropTypes.object.isRequired,
  darSectionActive: PropTypes.bool
};

export default withApplicationContext(withProduct(withLabels(ProductPrices)));
